import React, { useEffect, useState } from 'react';
import './blinkingText.css';

const BlinkingText = (props) => {
    return (
        <div className='blinking-text-div'>
            <div className="blinking-text">{`A task operation is currently running for this ${props.entityType}. Please navigate to Task Tracker to check its status.`}</div>
        </div>
    );
}

export default BlinkingText;