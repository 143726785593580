import React, { useEffect, useState } from 'react'
import { Drawer } from '@material-ui/core'
import FilterSelector from '../filtersSelector/FiltersSelector'
import SkillDetailsTable from '../table/skillDetailsTable'
import './AddMembersDrawer.css'
import { useSelector } from 'react-redux'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const AddMembersDrawer = (props) => {
    const { open, toggle, anchor, entityID, entityName, entityType } = props;
    const [data, setData] = useState([]);
    const [allUsers, setAllUsers] = useState();
    const usersData = useSelector((state) => state.orgData.orgData.users);
    const groupsData = useSelector((state) => state.orgData.orgData.groups);
    const locationsData = useSelector((state) => state.orgData.orgData.locations);
    const rolesData = useSelector((state) => state.orgData.orgData.roles);
    const divisionsData = useSelector((state) => state.orgData.orgData.divisions);
    const [filterData, setFilterData] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [height, setHeight] = useState("100vh");

    useEffect(() => {
        const handleResize = () => {
            const newHeight = document.documentElement.clientHeight;
            setHeight(`${newHeight - 250}px`);
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // useEffect(() => {
    //     try {
    //         console.log(`[${entityID}]`)
    //         let users = JSON.parse(JSON.stringify(usersData));
    //         console.log('users:', users)
    //         switch (entityType.toLowerCase()) {
    //             case 'skill':
    //                 users = users.filter(x => {
    //                     const skill = x.skills.find(skill => skill.id === entityID)
    //                     if (skill) return true
    //                 }).map(user => ({
    //                     ...user,
    //                     division: user.divisions[0].name,
    //                     proficiency: user.skills.find(x => x.id === entityID).ratings
    //                 }))
    //                 break;
    //             case 'language':
    //                 users = users.filter(x => {
    //                     const language = x.languages.find(language => language.id === entityID)
    //                     if (language) return true
    //                 }).map(user => ({
    //                     ...user,
    //                     division: user.divisions[0].name,
    //                     proficiency: user.languages.find(x => x.id === entityID).ratings
    //                 }))
    //                 break;
    //             default:
    //                 break;
    //         }

    //         console.log('AddMembersDrawer:users:', users)
    //         setAllUsers(users)
    //         setData(users)
    //     } catch (error) {
    //         console.log('error:', error)
    //     }
    // }, [usersData])

    const getFilteredData = () => { }

    useEffect(() => {
        let pendingUsers, users
        switch (filterBy) {
            case 'members':
                pendingUsers = filterData
                    // .filter(user => !allUsers.some(x => x.id === user.id))
                    .map(user => ({
                        ...user,
                        proficiency: 0
                    }))

                console.log('members.pendingUsers:', pendingUsers)
                setData([...pendingUsers])
                break;
            case 'groups':
                users = JSON.parse(JSON.stringify(usersData))
                pendingUsers = users
                    .filter(user => user.groups.some(group => filterData.find(x => x.id === group.id)))
                    // .filter(user => !allUsers.some(x => x.id === user.id))
                    .map(user => ({
                        ...user,
                        proficiency: 0
                    }))
                console.log('groups.pendingUser:', pendingUsers)
                setData([...pendingUsers])
                break;
            case 'divisions':
                users = JSON.parse(JSON.stringify(usersData))
                pendingUsers = users
                    .filter(user => user.divisions.some(division => filterData.find(x => x.id === division.id)))
                    // .filter(user => !allUsers.some(x => x.id === user.id))
                    .map(user => ({
                        ...user,
                        proficiency: 0
                    }))
                console.log('divisions.pendingUser:', pendingUsers)
                setData([...pendingUsers])
                break;
            case 'locations':
                users = JSON.parse(JSON.stringify(usersData))
                pendingUsers = users
                    .filter(user => user.locations.some(location => filterData.find(x => x.id === location.id)))
                    // .filter(user => !allUsers.some(x => x.id === user.id))
                    .map(user => ({
                        ...user,
                        proficiency: 0
                    }))
                console.log('locations.pendingUser:', pendingUsers)
                setData([...pendingUsers])
                break;
            case 'reportsto':
                users = JSON.parse(JSON.stringify(usersData))
                pendingUsers = users
                    .filter(user => user.reportsTo.some(reportsTo => filterData.find(x => x.id === reportsTo.id)))
                    // .filter(user => !allUsers.some(x => x.id === user.id))
                    .map(user => ({
                        ...user,
                        proficiency: 0
                    }))
                console.log('reportsto.pendingUser:', pendingUsers)
                setData([...pendingUsers])
                break;
            case 'roles':
                users = JSON.parse(JSON.stringify(usersData))
                pendingUsers = users
                    .filter(user => user.roles.some(role => filterData.find(x => x.id === role.id)))
                    // .filter(user => !allUsers.some(x => x.id === user.id))
                    .map(user => ({
                        ...user,
                        proficiency: 0
                    }))
                console.log('roles.pendingUser:', pendingUsers)
                setData([...pendingUsers])
                break;
            default:
                break;
        }
    }, [filterData, filterBy, usersData])

    const filtersChanged = (filterBy, filterData) => {
        console.log('AddMembersDrawer.filtersChanged.filterBy', filterBy)
        console.log('AddMembersDrawer.filtersChanged.filterData', filterData)
        setFilterBy(filterBy);
        setFilterData(filterData);
    }

    return (
        <Drawer open={open} anchor={anchor} onClose={toggle(false)}>
            <div style={{ margin: "5px", height: height }}>
                <div className='d-flex justify-content-between align-items-center'>
                    <h5 style={{ marginLeft: 5 }}>Add Members</h5>
                    <IconButton
                        aria-label="close"
                        onClick={toggle(false)}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <FilterSelector
                    members={JSON.parse(JSON.stringify(usersData))}
                    groups={JSON.parse(JSON.stringify(groupsData))}
                    divisions={JSON.parse(JSON.stringify(divisionsData))}
                    locations={JSON.parse(JSON.stringify(locationsData))}
                    reportsTo={JSON.parse(JSON.stringify(usersData))}
                    roles={JSON.parse(JSON.stringify(rolesData))}
                    selectedFiltersChanged={filtersChanged}
                />
                <SkillDetailsTable
                    rowContent={data}
                    hiddenColumns={['email', 'division']}
                    styles={{
                        root: {
                            display: "flex",
                            flexDirection: 'column',
                        }
                    }}
                    ratingSize='large'
                    entityType={entityType}
                    entityID={entityID}
                    entityName={entityName}
                    resetFilterEnabled={() => { }}
                    resetFilterRatings={getFilteredData}
                    resetFilteredMembers={filtersChanged}
                />
            </div>
        </Drawer>
    )
}

export default AddMembersDrawer