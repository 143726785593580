// actions.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDivisions, getDynamicGroups, getEntities, getGroups, getLocations, getRoles, getTaskOperation, getUsers } from '../../services/genesysSdk';


export const getAllOrgDetails = createAsyncThunk('orgData/getAllOrgDetails', async (payload, { getState, rejectWithValue }) => {
    const currentState = getState();
    console.log("getAllOrgDetails 1");
    try {
        const token = sessionStorage.getItem('purecloud-csp-token');
        const env = sessionStorage.getItem('purecloud-csp-env');
        const promises = [
            getDynamicGroups(token, env),
            getUsers(token, env),
            getTaskOperation(token, env),
            getDivisions(token, env),
            getRoles(token, env),
            getLocations(token, env),
            getGroups(token, env),
            getEntities(token, env)
        ]
        const responses = await Promise.all(promises);

        // const response = await backendAPI.getTaskOperation(token, env);
        // console.log(response);

        let allJobList = [];
        if (responses[2]?.length > 0) {
            let taskList = responses[2]?.sort((a, b) => b.taskCount - a.taskCount);
            taskList.filter(x => {
                if (x.status !== "Completed" && x.status !== "Failed") {
                    allJobList.push({ entityName: x.entityName, batchId: x.batchId });
                }
            })
            // dispatch(setSaveBatchId({ latestSavedBatchId: allJobList }))
        }

        // const savedTaskList = currentState.orgData.listOfTaskJobs;
        // let saveIds = currentState.orgData.latestSavedBatchId;
        // let saveBatchIds = [...saveIds];

        // for (let sk = 0; sk < saveBatchIds.length; sk++) {
        //     const checkIdPresent = savedTaskList.find(x => x.batchId === saveBatchIds[sk].batchId)
        //     if (checkIdPresent && (checkIdPresent.status === "Completed" || checkIdPresent.status === "Failed")) {
        //         saveBatchIds = saveBatchIds.filter(x => x.batchId !== checkIdPresent.batchId);
        //     }
        // }

        return {
            orgLatestData: {
                dynamicGroups: responses[0],
                users: responses[1],
                divisions: responses[3],
                roles: responses[4],
                locations: responses[5],
                groups: responses[6],
                entities: responses[7],
            },
            saveBatchIds: allJobList
        }
    } catch (error) {
        console.error(error);
        return rejectWithValue(error);
    }
});

export const getEntitiesAction = createAsyncThunk('orgData/getEntities', async (_, { getState, rejectWithValue }) => {
    const token = sessionStorage.getItem('purecloud-csp-token');
    const env = sessionStorage.getItem('purecloud-csp-env');
    try {
        const entites = await getEntities(token, env);
        return entites
    } catch (error) {
        console.error(error);
        return rejectWithValue(error);
    }
});
