import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useLocation, useHistory } from 'react-router-dom'
import Header from '../../header/Header';
import FilterListIcon from '@material-ui/icons/FilterList';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Typography from '@material-ui/core/Typography';
import SkillDetailsTable from '../../table/skillDetailsTable'
import AddMembersDrawer from '../../addMembersDrawer/AddMembersDrawer';
import { FormControl } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Popover from '@material-ui/core/Popover';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FilterSelector from '../../filtersSelector/FiltersSelector'
import SkillDetailModal from '../../modals/skillDetailModal/SkillDetailModal'
import Rating from '@material-ui/lab/Rating';
import './SkillDetailsPage.css';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList';
import BlinkingText from '../../blinkingText/blinkingText';

const SkillDetailsPage = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const listOfSaveBtachIds = useSelector((state) => state.orgData.latestSavedBatchId);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [entityUsers, setEntityUsers] = useState([]);
  const id = undefined;
  const [anchorEl, setAnchorEl] = useState(null);
  const openPop = Boolean(anchorEl);
  const [filteredValues, setFilteredValues] = useState([]);
  const [oneCheckbox, setOneCheckbox] = useState(false);
  const [twoCheckbox, setTwoCheckbox] = useState(false);
  const [threeCheckbox, setThreeCheckbox] = useState(false);
  const [fourCheckbox, setFourCheckbox] = useState(false);
  const [fiveCheckbox, setFiveCheckbox] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const [modalContent, setModalContent] = useState('')
  const [button1, setButton1] = useState('')
  const [button2, setButton2] = useState('')
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [selectedUsers, setSelectedUsers] = useState([])
  const [numberOfSelectedUsers, setNumberOfSelectedUsers] = useState([])
  const [disableActionsButton, setDisableActionsButton] = useState(true)
  const [disableDrawerButton, setDisableDrawerButton] = useState(false)
  const [pendingMembers, setPendingMembers] = useState([])
  const [tempDivisions, setTempDivisions] = useState([])
  const [tempRoles, setTempRoles] = useState([])
  const [tempGroups, setTempGroups] = useState([])
  const [tempLocations, setTempLocations] = useState([])
  const [tempReportsTo, setTempReportsTo] = useState([])
  let filteredDivisions, filteredRoles, filteredGroupIDs, filteredLocationIDs, filteredReportsToIDs = []
  let filteredGroups = new Array(); let filteredLocations = new Array(); let filteredReportsTo = new Array();
  const [filterBy, setFilterBy] = useState()
  const [filterData, setFilterData] = useState([])
  const [isFilterEnabled, setFilterEnalbed] = useState(false);
  const usersData = useSelector((state) => state.orgData.orgData.users);
  const groupsData = useSelector((state) => state.orgData.orgData.groups);
  const locationsData = useSelector((state) => state.orgData.orgData.locations);
  const entityID = useSelector((state) => state.orgData.selectedEntity.entityID);
  const entityType = useSelector((state) => state.orgData.selectedEntity.entityType);
  const entityName = useSelector((state) => state.orgData.selectedEntity.entityName);
  const history = useHistory();
  const [containerHeight, setContainerHeight] = useState("100vh");

  const toggleDrawer = (open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer(open)
  }

  useEffect(() => {
    const handleResize = () => {
      const newHeight = document.documentElement.clientHeight;
      setContainerHeight(`${newHeight - 350}px`)
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchUserData = () => {
      let users = JSON.parse(JSON.stringify(usersData))
      switch (entityType.toLowerCase()) {
        case 'skill':
          users = users.filter(x => {
            const skill = x.skills.find(skill => skill.id === entityID)
            if (skill) return true
          }).map(user => ({
            ...user,
            division: user.divisions[0].name,
            proficiency: user.skills.find(x => x.id === entityID).ratings
          }))
          break;
        case 'language':
          users = users.filter(x => {
            const language = x.languages.find(language => language.id === entityID)
            if (language) return true
          }).map(user => ({
            ...user,
            division: user.divisions[0].name,
            proficiency: user.languages.find(x => x.id === entityID).ratings
          }))
          break;
        default:
          break;
      }
      setEntityUsers(users)
      setFilteredUsers(users)
      setPendingMembers(users)
    }
    fetchUserData();
  }, [usersData])
  console.log('filteredUsers:', filteredUsers)

  const removeDuplicates = (array) => {
    let jsonObject = array.map(JSON.stringify);
    let uniqueSet = new Set(jsonObject);
    let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
    return (uniqueArray);
  }

  tempDivisions.push(...filteredUsers.map(x => (x.divisions)).flat(Infinity))
  tempRoles.push(...filteredUsers.map(x => (x.roles)).flat(Infinity))
  tempGroups.push(...filteredUsers.map(x => (x.groups)).flat(Infinity))
  tempLocations.push(...filteredUsers.map(x => (x.locations)).flat(Infinity))
  tempReportsTo.push(...filteredUsers.map(x => (x.reportsTo)).flat(Infinity))

  filteredDivisions = removeDuplicates(tempDivisions)
  filteredRoles = removeDuplicates(tempRoles)
  filteredGroupIDs = JSON.parse(JSON.stringify(removeDuplicates(tempGroups))).map(x => x.id)
  filteredLocationIDs = JSON.parse(JSON.stringify(removeDuplicates(tempLocations))).map(x => x.id)
  filteredReportsToIDs = JSON.parse(JSON.stringify(removeDuplicates(tempReportsTo))).map(x => x.id)

  filteredGroupIDs.forEach(id => {
    filteredGroups.push(...JSON.parse(JSON.stringify(groupsData)).filter(x => x.id === id))
  })

  filteredLocationIDs.forEach(id => {
    filteredLocations.push(...JSON.parse(JSON.stringify(locationsData)).filter(x => x.id === id))
  })

  filteredReportsToIDs.forEach(id => {
    filteredReportsTo.push(...JSON.parse(JSON.stringify(usersData)).filter(x => x.id === id))
  })

  const handleClose1 = () => {
    if (pendingMembers.length > 0)
      setFilteredUsers(pendingMembers);
    else
      setFilteredUsers(entityUsers)
    setAnchorEl(null);
    setOneCheckbox(false);
    setTwoCheckbox(false);
    setThreeCheckbox(false);
    setFourCheckbox(false);
    setFiveCheckbox(false);
    setFilteredValues([]);
  };

  const parametrizedFilteredData = (users) => {
    let filteredRatingUsers = []
    let ratingFilter = []
    console.log(users)
    if (filteredValues.length > 0) {
      filteredValues.forEach(element => {
        ratingFilter = users.filter(x => x.proficiency === element);
        filteredRatingUsers = filteredRatingUsers.concat(ratingFilter)
      })
      console.log(filteredRatingUsers);
      setFilteredUsers(filteredRatingUsers);
      setPendingMembers(users)
    }
    else {
      setFilteredUsers(users)
      setPendingMembers(users)
    }
  }

  const filtersChanged = (filterBy, filterData) => {
    console.log('SkillDetailsPage.filtersChanged.filterBy', filterBy)
    console.log('SkillDetailsPage.filtersChanged.filterData', filterData)
    setFilterEnalbed(true);
    setFilterBy(filterBy)
    setFilterData(filterData)
  }

  useEffect(() => {
    let pendingUsers
    if (filterData.length > 0) {
      let users = JSON.parse(JSON.stringify(usersData));
      switch (filterBy) {
        case 'all':
          setFilteredUsers(entityUsers)
          setPendingMembers(entityUsers)
          setOneCheckbox(false);
          setTwoCheckbox(false);
          setThreeCheckbox(false);
          setFourCheckbox(false);
          setFiveCheckbox(false);
          setFilteredValues([])
          break;

        case 'members':
          pendingUsers = entityType === "Skill" ?
            filterData
              .filter(user => entityUsers.some(x => x.id === user.id))
              .map(user => ({
                ...user,
                division: user.divisions[0].name,
                proficiency: user.skills.find(x => x.id === entityID).ratings
              })) :
            filterData
              .filter(user => entityUsers.some(x => x.id === user.id))
              .map(user => ({
                ...user,
                division: user.divisions[0].name,
                proficiency: user.languages.find(x => x.id === entityID).ratings
              }))
          break;

        case 'groups':
          pendingUsers = entityType === 'Skill' ? (users
            .filter(user => user.groups.some(group => filterData.find(x => x.id === group.id)))
            .filter(user => entityUsers.some(x => x.id === user.id))
            .map(user => ({
              ...user,
              division: user.divisions[0].name,
              proficiency: user.skills.find(x => x.id === entityID).ratings
            }))) :
            (users
              .filter(user => user.groups.some(group => filterData.find(x => x.id === group.id)))
              .filter(user => entityUsers.some(x => x.id === user.id))
              .map(user => ({
                ...user,
                division: user.divisions[0].name,
                proficiency: user.languages.find(x => x.id === entityID).ratings
              })))
          break;

        case 'divisions':
          pendingUsers = entityType === 'Skill' ? (users
            .filter(user => user.divisions.some(division => filterData.find(x => x.id === division.id)))
            .filter(user => entityUsers.some(x => x.id === user.id))
            .map(user => ({
              ...user,
              division: user.divisions[0].name,
              proficiency: user.skills.find(x => x.id === entityID).ratings
            }))) :
            (users
              .filter(user => user.divisions.some(division => filterData.find(x => x.id === division.id)))
              .filter(user => entityUsers.some(x => x.id === user.id))
              .map(user => ({
                ...user,
                division: user.divisions[0].name,
                proficiency: user.languages.find(x => x.id === entityID).ratings
              })))
          break;

        case 'locations':
          pendingUsers = entityType === 'Skill' ? (users
            .filter(user => user.locations.some(location => filterData.find(x => x.id === location.id)))
            .filter(user => entityUsers.some(x => x.id === user.id))
            .map(user => ({
              ...user,
              division: user.divisions[0].name,
              proficiency: user.skills.find(x => x.id === entityID).ratings
            }))) :
            (users
              .filter(user => user.locations.some(location => filterData.find(x => x.id === location.id)))
              .filter(user => entityUsers.some(x => x.id === user.id))
              .map(user => ({
                ...user,
                division: user.divisions[0].name,
                proficiency: user.languages.find(x => x.id === entityID).ratings
              })))
          break;

        case 'reportsto':
          pendingUsers = entityType === 'Skill' ? (users
            .filter(user => user.reportsTo.some(reportsTo => filterData.find(x => x.id === reportsTo.id)))
            .filter(user => entityUsers.some(x => x.id === user.id))
            .map(user => ({
              ...user,
              division: user.divisions[0].name,
              proficiency: user.skills.find(x => x.id === entityID).ratings
            }))) :
            (users
              .filter(user => user.reportsTo.some(reportsTo => filterData.find(x => x.id === reportsTo.id)))
              .filter(user => entityUsers.some(x => x.id === user.id))
              .map(user => ({
                ...user,
                division: user.divisions[0].name,
                proficiency: user.languages.find(x => x.id === entityID).ratings
              })))
          break;

        case 'roles':
          pendingUsers = entityType === 'Skill' ? (users
            .filter(user => user.roles.some(role => filterData.find(x => x.id === role.id)))
            .filter(user => entityUsers.some(x => x.id === user.id))
            .map(user => ({
              ...user,
              division: user.divisions[0].name,
              proficiency: user.skills.find(x => x.id === entityID).ratings
            }))) :
            (users
              .filter(user => user.roles.some(role => filterData.find(x => x.id === role.id)))
              .filter(user => entityUsers.some(x => x.id === user.id))
              .map(user => ({
                ...user,
                division: user.divisions[0].name,
                proficiency: user.languages.find(x => x.id === entityID).ratings
              })))
          break;
        default:
          break;
      }
      parametrizedFilteredData(pendingUsers)
    }
    else {
      parametrizedFilteredData(entityUsers)
    }
  }, [filterBy, filterData, entityUsers])


  const filterRating = (selected, labelname) => {
    setFilterEnalbed(true);
    let filteredVal = [];
    if (selected === true) {
      filteredVal = [...filteredValues, labelname];
      setFilteredValues(filteredVal)
    } else {
      const deleteIndex = filteredValues.findIndex(obj => obj === labelname)
      filteredValues.splice(deleteIndex, 1)
      filteredVal = filteredValues;
    }

    let filteredRatingUsers = []
    let ratingFilter = []
    if (filteredVal.length > 0) {
      filteredVal.forEach(element => {
        ratingFilter = pendingMembers.filter(x => x.proficiency === element);
        filteredRatingUsers = filteredRatingUsers.concat(ratingFilter)
      })
      setFilteredUsers(filteredRatingUsers);
    }
    else
      setFilteredUsers(pendingMembers);
    setAnchorEl(null);
  }

  const getFilteredData = () => {
    let filteredRatingUsers = []
    let ratingFilter = []
    if (filteredValues.length > 0) {
      filteredValues.forEach(element => {
        ratingFilter = pendingMembers.filter(x => x.proficiency === element);
        filteredRatingUsers = filteredRatingUsers.concat(ratingFilter)
      })
      setFilteredUsers(filteredRatingUsers);
    }
    else
      setFilteredUsers(pendingMembers);
    setAnchorEl(null);
  }

  const currentlySelected = (params) => {
    setSelectedUsers(params)
    setNumberOfSelectedUsers(params.length)
    if (params.length > 0) {
      setDisableActionsButton(false)
      setDisableDrawerButton(true)
    }
    else {
      setDisableActionsButton(true)
      setDisableDrawerButton(false)
    }
  }

  const resetFilterEnabled = () => {
    setFilterEnalbed(false);
  }

  return (
    <div id="wrap">
      <Header />
      <TabContext value='skills'>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={(_event, newValue) => {
              history.push("/skills/skillResults?tabValue=" + newValue);
              return;
            }}
            aria-label="lab API tabs example"
          >
            <Tab label="Skill Management" value="skills" onClick={() => {
              history.push("/skills/skillResults?tabValue=skills");
              return;
            }} />
            <Tab label="Group Assignment" value="groups" />
            <Tab label="Task Tracker" value="status" />
          </TabList>
        </Box>
      </TabContext>
      <br />
      <div style={{ display: "flex" }}>
        <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: 15 }}>
          <Typography color="textPrimary"><span style={{ fontSize: "16px", fontWeight: "600" }}>{entityType} :  {entityName}</span></Typography>
        </Breadcrumbs>
        {listOfSaveBtachIds.find(x => x.entityName === entityName) && <BlinkingText entityType={entityType} />}

      </div>
      <br />
      <SkillDetailModal
        open={open}
        handleClose={handleClose}
        modalTitle={'Remove Members'}
        modalContent={modalContent}
        button1={button1}
        button2={button2}
        entityType={entityType}
        entityID={entityID}
        entityName={entityName}
        selectedMembers={selectedUsers}
      />

      <Container style={{ overflow: 'visible' }}>
        <Row>
          <Col>
            <div className="parent d-flex align-items-center">
              <FilterSelector
                members={(entityType === "Skill") ? (JSON.parse(JSON.stringify(usersData)).filter(x => x.skills.find(x => x.id === entityID))) : (JSON.parse(JSON.stringify(usersData)).filter(x => x.languages.find(x => x.id === entityID)))}
                groups={filteredGroups}
                divisions={filteredDivisions}
                locations={filteredLocations}
                reportsTo={filteredReportsTo}
                roles={filteredRoles}
                selectedFiltersChanged={filtersChanged}
                styles={{
                  root: {
                    width: "35%",
                    marginRight: "5px"
                  }
                }}
              />
              <div className="d-flex align-items-center" style={{ marginLeft: 5, marginTop: "5px" }}>
                <Button variant="contained" style={{ textTransform: 'none', backgroundColor: "rgb(35, 57, 93)", color: "white" }} onClick={e => { setAnchorEl(e.currentTarget); }}><FilterListIcon />Filter</Button>
                <Popover
                  id={id}
                  open={openPop}
                  anchorEl={anchorEl}
                  onClose={e => { setAnchorEl(null) }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <FormControl className="form">
                    <FormControlLabel
                      control={
                        <Checkbox style={{
                          color: "grey", marginLeft: 5, height: "20px",
                          boxSizing: "border-box"
                        }} onChange={e => { setOneCheckbox(e.target.checked); filterRating(e.target.checked, 1) }} checked={oneCheckbox} />} label={<Rating readOnly value={1} size={"medium"} style={{ color: "#444a52" }} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox style={{
                          color: "grey", marginLeft: 5, height: "20px",
                          boxSizing: "border-box"
                        }} onChange={e => { setTwoCheckbox(e.target.checked); filterRating(e.target.checked, 2) }} checked={twoCheckbox} />} label={<Rating readOnly value={2} size={"medium"} style={{ color: "#444a52" }} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox style={{
                          color: "grey", marginLeft: 5, height: "20px",
                          boxSizing: "border-box"
                        }} onChange={e => { setThreeCheckbox(e.target.checked); filterRating(e.target.checked, 3) }} checked={threeCheckbox} />} label={<Rating readOnly value={3} size={"medium"} style={{ color: "#444a52" }} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox style={{
                          color: "grey", marginLeft: 5, height: "20px",
                          boxSizing: "border-box"
                        }} onChange={e => { setFourCheckbox(e.target.checked); filterRating(e.target.checked, 4) }} checked={fourCheckbox} />} label={<Rating readOnly value={4} size={"medium"} style={{ color: "#444a52" }} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox style={{
                          color: "grey", marginLeft: 5, height: "20px",
                          boxSizing: "border-box"
                        }} onChange={e => { setFiveCheckbox(e.target.checked); filterRating(e.target.checked, 5) }} checked={fiveCheckbox} />} label={<Rating readOnly value={5} size={"medium"} style={{ color: "#444a52" }} />}
                    />
                  </FormControl>
                </Popover>
              </div>
            </div>
          </Col>
          <Col>
            <div style={{ float: 'right' }}>
              <Button variant="contained" disabled={disableActionsButton} style={{ textTransform: 'none', marginRight: 5, backgroundColor: "rgb(35, 57, 93)", color: "white" }} onClick={() => {
                setModalContent("Remove the selected <b>" + numberOfSelectedUsers + "</b> member(s) from " + entityType + "?")
                setButton1('Yes')
                setButton2('No')
                handleClickOpen()
              }} >

                &nbsp;Remove User
              </Button>
              <Button disabled={disableDrawerButton} onClick={toggleDrawer(true)} variant="contained" style={{ textTransform: 'none', backgroundColor: "rgb(35, 57, 93)", color: "white" }}>
                <AddCircleIcon />
                &nbsp;Add Members
              </Button>
              <AddMembersDrawer
                open={openDrawer}
                toggle={toggleDrawer}
                anchor='right'
                entityID={entityID}
                entityName={entityName}
                entityType={entityType}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <br />
      <Container style={{ height: containerHeight, width: "100%" }}>
        <SkillDetailsTable
          rowContent={filteredUsers}
          hiddenColumns={[]}
          styles={{
            root: {
              display: "flex",
              flexDirection: 'column',
            }
          }}
          ratingSize='medium'
          entityType={entityType}
          entityID={entityID}
          entityName={entityName}
          tableOnClick={currentlySelected}
          resetFilterRatings={getFilteredData}
          resetFilteredMembers={filtersChanged}
          isFilterEnabled={isFilterEnabled}
          resetFilterEnabled={resetFilterEnabled}
          filterBy={filterBy}
          filterData={filterData} />
      </Container>
    </div>
  )
}

export default SkillDetailsPage
