import { configureStore } from "@reduxjs/toolkit";
import orgDataReducer from './orgData/orgDataSlice'

const store = configureStore({
  reducer: {
    orgData: orgDataReducer,
    devTools: true,
  },
});

export default store;