import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import Header from '../../header/Header';
import './SkillResultsView.css'
import SkillModal from '../../modals/skillModal/SkillModal'
import { DataGrid } from '@mui/x-data-grid';
import { TextField, FormControl, MenuItem, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import backendAPI from '../../../services/backend';
import Button from '@material-ui/core/Button';
import AddIcon from '@mui/icons-material/Add';
import AlertModal from '../../modals/alertModal/AlertModal'
import ConfirmationModal from '../../modals/confirmationModal/ConfirmationModal'
import WaitingModal from '../../modals/waitingModal/WaitingModal'
import GroupIcon from '@material-ui/icons/Group';
import DeleteIcon from '@material-ui/icons/Delete';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import SelectedGroupMembersTable from '../../table/selectedGroupMembersTable'
import AddGroupsDrawer from '../../addGroupsDrawer/AddGroupsDrawer';
import AddSkillsDrawer from '../../addSkillsDrawer/AddSkillsDrawer';
import RemoveSkillsDrawer from '../../removeSkillsDrawer/RemoveSkillsDrawer';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete } from '@material-ui/lab'
import { useDispatch, useSelector } from "react-redux";
import { getEntitiesAction } from '../../../redux/orgData/orgDataActions';
import { setWaitingMdlOpen, addNewEntityToState, removeNewEntityFromState, setSelectedEntity, setPullTaskStatus } from '../../../redux/orgData/orgDataSlice';
import TaskStatus from './taskStatus/TaskStatus';


const useStyles = makeStyles({
  root: {
    '& .MuiDataGrid-cell': {
      borderRight: '1px solid #e7e7e7',
    },
  },
});

const SkillResultsView = () => {
  const [pageSize, setPageSize] = useState(25);
  const history = useHistory();
  const [allEntities, setAllEntitites] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalTitle, setModaltitle] = useState("");
  const [contentAvailable, setContentAvailable] = useState(true);
  const [button1, setButton1] = useState('');
  const [button2, setButton2] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [type, setType] = useState('All Selected');
  const [filteredData, setfilteredData] = useState([]);
  const [membersAssigned, setMembersAssigned] = useState(0);
  const [eventTriggered, setEventTriggered] = useState('');
  const [entityType, setEntityType] = useState();
  const [entityName, setEntityName] = useState();
  const [entityID, setEntityID] = useState('');
  const [filtertext, setFilterText] = useState('');
  const [waitingModalHeader, setWaitingModalHeader] = useState('');
  const [message, setMessage] = useState();
  const [cancelMessage, setCancelMessage] = useState();
  const [error, setError] = useState();
  const [value, setValue] = useState("skills");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [selectedUsersForSkillChange, setSelectedUsersForSkillChange] = useState([]);
  const token = sessionStorage.getItem('purecloud-csp-token');
  const env = sessionStorage.getItem('purecloud-csp-env');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openSkillsDrawer, setOpenSkillsDrawer] = useState(false);
  const [disableAssignRemoveButton, setDisableAssignRemoveButton] = useState(true);
  const [openSkillsDrawerRemove, setOpenSkillsDrawerRemove] = useState(false);
  const [skillChangesComplete, setSkillChangesComplete] = useState(true);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState([]);
  const orgData = useSelector((state) => state.orgData.orgData);
  const usersData = useSelector((state) => state.orgData.orgData.users);
  const isWaitingModalOpen = useSelector((state) => state.orgData.IsWaitingMdlOpen);
  const dynamicgroupsEnabled = useSelector((state) => state.orgData.dynamicgroupsEnabled);
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [skillMmgtheight, setSkillMmgtheight] = useState("100vh");
  const [groupAssignmentheight, setGroupAssignmentheight] = useState("100vh");

  useEffect(() => {
    const handleResize = () => {
      const newHeight = document.documentElement.clientHeight;
      setSkillMmgtheight(`${newHeight - 210}px`);
      setGroupAssignmentheight(`${newHeight - 300}px`)
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabValue = queryParams.get('tabValue');
    if (tabValue) {
      setValue(tabValue);
    }
    setWaitingModalHeader("Loading Skills and Languages");
    return () => {
      setFilterText("");
      setType('All Selected')
    }
  }, [location.search])

  const toggleCloseDrawer = (close) => {
    setOpenDrawer(close)
    setOpenSkillsDrawer(close)
    setOpenSkillsDrawerRemove(close)
  }

  const toggleDrawer = (open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer(open)
  }

  const toggleSkillsDrawer = (open) => async (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenSkillsDrawer(open)
  }
  const toggleRemoveSkillsDrawer = (open) => async (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenSkillsDrawerRemove(open)
  }
  // console.log("Sorted All Entities: ", allEntities)

  const handleClose = () => setOpen(false);
  const handleClickOpen = () => setOpen(true);
  const handleClosePop = () => { setAnchorEl(null) };

  const handleSubmit = (entity) => {
    if (entityID) {
      entity['entityID'] = entityID;
    }
    if (eventTriggered === "add_skill_language")
      addNewEntity(entity)
    if (eventTriggered === "delete_skill_language")
      deleteExistingEntity(entity)
    handleClose()
    dispatch(setPullTaskStatus({ isPullTaskStatus: true }))
  }

  useEffect(() => {
    if (orgData?.entities) {
      const entities = (JSON.parse(JSON.stringify(orgData?.entities)) || []).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      setAllEntitites(entities);
      setfilteredData(entities);
      if (type === "All Selected") {
        setfilteredData(entities.filter(x => (x.name.toLowerCase().includes(filtertext.toLowerCase()))))
      } else {
        setfilteredData(entities.filter(x => (x.type === type) && (x.name.toLowerCase().includes(filtertext.toLowerCase()))))
      }
    }
  }, [orgData?.entities])

  useEffect(() => {
    const entities = (JSON.parse(JSON.stringify(orgData?.entities)) || []).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    if (type === "All Selected") {
      setfilteredData(entities.filter(x => x.name.toLowerCase().includes(filtertext.toLowerCase())));
    } else {
      setfilteredData(entities.filter(x => x.type === type && x.name.toLowerCase().includes(filtertext.toLowerCase())));
    }
  }, [type, filtertext])

  const addNewEntity = async (entity) => {
    try {
      dispatch(setWaitingMdlOpen({ IsWaitingMdlOpen: true }));
      setWaitingModalHeader(`Creating new ${entity.skilltype} "${entity.skillname}"`)
      const addEntitiesDataResult = await backendAPI.addEntity(token, env, entity)
      console.debug("SkillResultsView.addNewEntity", addEntitiesDataResult)
      let newEntity = addEntitiesDataResult[entity?.skilltype.toLowerCase()]?.[0];
      newEntity.memberCount = 0;
      dispatch(addNewEntityToState(newEntity));
      setMessage(`The ${entity.skilltype} "${entity.skillname}" has been created successfully`)
      setTimeout(() => {
        setMessage()
      }, 2000);
    } catch (error) {
      setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
      console.error(error)
    } finally {
      dispatch(setWaitingMdlOpen({ IsWaitingMdlOpen: false }));
    }
  }

  const deleteExistingEntity = async (entity) => {
    try {
      dispatch(setWaitingMdlOpen({ IsWaitingMdlOpen: true }));
      setWaitingModalHeader(`Deleting the ${entity.skilltype} "${entity.skillname}"`)
      const deleteEntitiesDataResult = await backendAPI.deleteEntity(token, env, entity)
      console.log(deleteEntitiesDataResult)

      setMessage(`The ${entity.skilltype} "${entity.skillname}" has been deleted successfully`)
      setTimeout(() => {
        dispatch(removeNewEntityFromState({ name: entity.skillname }))
        setMessage()
      }, 2000);
    } catch (error) {
      setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
      console.error(error)
    } finally {
      dispatch(setWaitingMdlOpen({ IsWaitingMdlOpen: false }));
    }
  }

  const currentlySelected = (params) => {
    const value = params.colDef.field;
    if (value !== "actions")
      return;
    if (params.getValue(params.id, 'type') === 'skill')
      setEntityType('Skill')
    if (params.getValue(params.id, 'type') === 'language')
      setEntityType('Language')
    setEntityName(params.getValue(params.id, 'name'))
    setEntityID(params.id)
    setMembersAssigned(params.getValue(params.id, 'memberCount'))
  };

  const currentlySelectedMembers = (params) => {
    setSelectedUsersForSkillChange(params)
    if (params.length > 0)
      setDisableAssignRemoveButton(false)
    else
      setDisableAssignRemoveButton(true)
  }

  const selectedGroupsforSearch = (event, value) => {
    setAutoCompleteValue(value, selectedAgents)
    if (value.length > 0) {
      let filteredMembers = new Set()
      for (let i = 0; i < value.length; i++) {
        if (value[i].type === "dynamic") {
          for (let k = 0; k < selectedAgents.length; k++) {
            if (selectedAgents[k].selectedGroups.includes(value[i].name))
              filteredMembers.add(selectedAgents[k])
          }
        }
        else {
          for (let l = 0; l < selectedAgents.length; l++) {
            for (let m = 0; m < selectedAgents[l].groups.length; m++) {
              if (selectedAgents[l].groups[m].id === value[i].id)
                filteredMembers.add(selectedAgents[l])
            }
          }
        }
      }
      filteredMembers = [...filteredMembers]
      console.info("SkillResultView.selectedGroupsForSearch: GroupsSelection", filteredMembers)
      setFilteredUsers(filteredMembers)
    }
    else
      setFilteredUsers(selectedAgents)
  }

  const columnheaders = [
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      resizable: false,
      minWidth: 100,
      flex: 0.65,
      type: 'string',
      headerClassName: 'super-app-theme--header'

    },
    {
      field: "type",
      headerName: "Type",
      sortable: false,
      resizable: false,
      minWidth: 100,
      flex: 0.35,
      type: 'string',
      headerClassName: 'super-app-theme--header'

    },
    {
      field: "memberCount",
      headerName: "Members Assigned",
      sortable: false,
      resizable: false,
      minWidth: 100,
      flex: 0.35,
      type: 'string',
      headerClassName: 'super-app-theme--header'

    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      resizable: false,
      type: 'string',
      headerClassName: 'super-app-theme--header',
      width: 300,
      renderCell: (params) => {
        const { name, id, type, memberCount } = params.row;

        return (
          <div>
            <GroupIcon
              style={{ width: '2em', color: "#606060", cursor: "pointer" }}
              onClick={() => {
                currentlySelected(params)
                let types = 'Skill';
                if (type === 'language') {
                  types = 'Language';
                }
                setTimeout(() => {
                  history.push({
                    pathname: "/skills/SkillDetailsPage",
                    state: { entityName: name, entityID: id, entityType: types },
                  });
                }, 100)
                dispatch(setSelectedEntity({
                  entityName: name,
                  entityID: id,
                  entityType: types
                }))
              }}
            />
            <DeleteIcon
              style={{ width: '2em', color: "#606060", cursor: "pointer" }}
              onClick={() => {
                currentlySelected(params)
                setModaltitle(
                  `Delete this ${type.toLowerCase()}?`
                );
                setContentAvailable(false);
                setButton1("Yes");
                setButton2("No");
                handleClickOpen();
                handleClosePop();
                setEventTriggered("delete_skill_language");
              }}
            />
          </div>
        )
      }
    }
  ];

  const handleSkillsGroups = (event, newValue) => {
    setValue(newValue)
    console.log("SkillResultsView.HandleSkillsGroups: Current tab is ", newValue)
    setFilterText('')
    setType('All Selected')

  }

  const selectedGroupmembers = (selectedmembers, selectedGroups) => {
    console.info('SkillResultsView.selectedGroupMembers: ', selectedmembers, selectedGroups)
    selectedmembers = selectedmembers.map(user => ({
      ...user,
      division: user.divisions[0].name
    }))
    setFilteredUsers(selectedmembers)
    setSelectedAgents(selectedmembers)
    setSelectedGroups(selectedGroups)
  }

  useEffect(() => {
    let userListObj = {};
    for (let i = 0; i < usersData.length; i++) {
      userListObj[usersData[i].id] = usersData[i];
    }

    const result = filteredUsers.map(element => {
      if (userListObj.hasOwnProperty(element.id)) {
        element.skills = [...userListObj?.[element.id]?.skills || []];
        element.languages = [...userListObj?.[element.id]?.languages || []];
      }
      return element;
    });
    setFilteredUsers(result);
    setSelectedAgents(result);
  }, [usersData])

  function updateAgentSkills(selectedmembers) {
    console.info('SkillResultsView.selectedGroupMembers: ', selectedmembers)
    selectedmembers = selectedmembers.filter(x => x != undefined)
    selectedmembers = selectedmembers.map(user => ({
      ...user,
      division: user.divisions[0].name
    }))
    setFilteredUsers(selectedmembers)
    setSelectedAgents(selectedmembers)
  }

  const enableFinishButton = () => {
    setSkillChangesComplete(false);
  }

  const cancelGroupAssignment = () => {
    setFilteredUsers([])
    setSkillChangesComplete(true)
    setCancelMessage()
    setSelectedGroups([])
    setAutoCompleteValue([])
  }

  const handleCloseCancelDialog = () => {
    setCancelMessage()
  }

  const setTabValue = (value) => {
    setValue(value);
  }

  return (
    <div>
      <Header />
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", display: 'flex', justifyContent: 'space-between' }}>
            <TabList
              sx={{
                ".Mui-selected": {
                  color: `rgb(35, 57, 93)`,
                },
              }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "rgb(35, 57, 93)",
                }
              }}
              onChange={handleSkillsGroups}
              aria-label="lab API tabs example"
            >
              <Tab label="Skill Management" value="skills" />
              <Tab label="Group Assignment" value="groups" />
              <Tab label="Task Tracker" value="status" />
            </TabList>
          </Box>
          <TabPanel value="skills">
            <SkillModal
              open={open}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              modalTitle={modalTitle}
              contentAvailable={contentAvailable}
              button1={button1}
              button2={button2}
              membersAssigned={membersAssigned}
              entityType={entityType}
              entityName={entityName}
            />
            <WaitingModal
              isOpen={isWaitingModalOpen}
              header={waitingModalHeader}
            />
            <ConfirmationModal
              isOpen={!!message}
              header="Success!"
              toggle={() => {
                setMessage(undefined);
              }}
              body={message}
            />

            <AlertModal
              isOpen={!!error}
              header="Error"
              toggle={() => {
                setError(undefined);
              }}
              body={error}
            />
            <div
              style={{
                width: "100%",
                marginBottom: "5px",
              }}
              className="d-flex justify-content-between"
            >
              <div className='d-flex' style={{ width: "50%" }}>
                <div style={{ marginRight: "5px" }}>
                  <FormControl variant="outlined">
                    <TextField
                      variant="outlined"
                      size="small"
                      style={{ minWidth: 150, width: "100%" }}
                      value={type}
                      select={true}
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        },
                      }}
                      onChange={(e) => {
                        if (e.target.value === 'All Selected') {
                          setType(e.target.value);
                        } else {
                          setType(e.target.value);
                        }
                      }}
                    >
                      <MenuItem value="All Selected">All Selected</MenuItem>
                      <MenuItem value="skill">Skill</MenuItem>
                      <MenuItem value="language">Language</MenuItem>
                    </TextField>
                  </FormControl>
                </div>

                <TextField
                  style={{ width: "100%", marginLeft: "5px" }}
                  InputProps={{
                    endAdornment: <SearchIcon style={{ color: "#e0e0e0" }} />,
                  }}
                  label="Search"
                  defaultValue=""
                  variant="outlined"
                  size="small"
                  onChange={(e) => {
                    setFilterText(e.target.value);
                  }}
                />
              </div>
              <div>
                <Button
                  variant="contained"
                  style={{
                    textTransform: "none",
                    float: "right",
                    backgroundColor: "rgb(35, 57, 93)",
                    color: "white",
                  }}
                  onClick={() => {
                    setModaltitle("Add Skill or Language");
                    setContentAvailable(true);
                    setButton1("Add");
                    setButton2("Cancel");
                    handleClickOpen();
                    setEventTriggered("add_skill_language");
                  }}
                >
                  <AddIcon />
                  Add Skill/Language
                </Button>
              </div>
            </div>

            <div style={{ height: skillMmgtheight }}>
              {filteredData && (
                <DataGrid
                  rows={filteredData}
                  columns={columnheaders}
                  pageSize={pageSize}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                  pagination
                  disableColumnFilter
                  disableColumnMenu
                  hideFooterSelectedRowCount
                  showColumnRightBorder
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  onCellClick={currentlySelected}
                  className={classes.root}
                />
              )}
            </div>
          </TabPanel>
          <TabPanel value="groups">
            <h5 style={{ fontWeight: '700' }}>Assign Skills and Languages to Groups</h5>
            <div className='d-flex justify-content-between'>
              <Dialog
                open={!!cancelMessage}
                onClose={handleCloseCancelDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title" style={{ backgroundColor: "rgb(35, 57, 93)", color: "white" }}>
                  {"Cancel Progress?"}
                  <IconButton
                    aria-label="close"
                    onClick={handleCloseCancelDialog}
                    style={{ float: 'right', textTransform: 'none', backgroundColor: "rgb(35, 57, 93)", color: "white" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {cancelMessage}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    style={{ textTransform: 'none', backgroundColor: "rgb(35, 57, 93)", color: "white" }}
                    onClick={cancelGroupAssignment}
                    autoFocus
                  >
                    Yes
                  </Button>
                  <Button color="primary" onClick={handleCloseCancelDialog}>
                    No
                  </Button>
                </DialogActions>
              </Dialog>

              <Autocomplete
                multiple
                style={{ width: "40vw" }}
                value={autoCompleteValue}
                options={selectedGroups}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  selectedGroupsforSearch(event, newValue);
                }}
                size='small'
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Search" />
                )}
              />


              <div>
                <Button
                  variant="contained"
                  style={{
                    textTransform: "none",
                    margin: 5,
                    backgroundColor: "rgb(35, 57, 93)",
                    color: "white",
                  }}
                  disabled={disableAssignRemoveButton}
                  onClick={toggleRemoveSkillsDrawer(true)}
                >
                  Remove
                </Button>
                <RemoveSkillsDrawer
                  open={openSkillsDrawerRemove}
                  toggle={toggleRemoveSkillsDrawer}
                  anchor="right"
                  selectedAgents={selectedUsersForSkillChange}
                  allSkillsLanguages={allEntities}
                  toggleCloseDrawer={toggleCloseDrawer}
                  modifiedAgentSkills={updateAgentSkills}
                  groupsUsers={filteredUsers}
                  enableFinishButton={enableFinishButton}
                  setTabValue={setTabValue}
                />

                <Button
                  variant="contained"
                  style={{
                    textTransform: "none",
                    margin: 5,
                    backgroundColor: "rgb(35, 57, 93)",
                    color: "white",
                  }}
                  disabled={disableAssignRemoveButton}
                  onClick={toggleSkillsDrawer(true)}
                >
                  Assign
                </Button>
                <AddSkillsDrawer
                  open={openSkillsDrawer}
                  toggle={toggleSkillsDrawer}
                  anchor="right"
                  selectedAgents={selectedUsersForSkillChange}
                  allSkillsLanguages={allEntities}
                  toggleCloseDrawer={toggleCloseDrawer}
                  modifiedAgentSkills={updateAgentSkills}
                  groupsUsers={filteredUsers}
                  enableFinishButton={enableFinishButton}
                  entityName={entityName}
                  entityId={entityID}
                  setTabValue={setTabValue}
                />

                <Button
                  variant="contained"
                  style={{
                    textTransform: "none",
                    margin: 5,
                    backgroundColor: "rgb(35, 57, 93)",
                    color: "white",
                  }}
                  onClick={toggleDrawer(true)}
                >
                  <AddIcon />
                  Add Groups
                </Button>
                {openDrawer && <AddGroupsDrawer
                  open={openDrawer}
                  toggle={toggleDrawer}
                  anchor="right"
                  selectedAgents={selectedGroupmembers}
                  toggleCloseDrawer={toggleCloseDrawer}
                  dynamicgroupsEnabled={dynamicgroupsEnabled}
                />}
              </div>
            </div>
            <div hidden={true}>Group names</div>
            <hr />
            <div style={{ height: groupAssignmentheight }}>
              <SelectedGroupMembersTable
                rowContent={filteredUsers}
                hiddenColumns={[]}
                styles={{
                  root: {
                    display: "flex",
                    flexDirection: "column",
                  },
                }}
                tableOnClick={currentlySelectedMembers}
              />
              <div style={{ marginTop: 10 }} hidden={!filteredUsers.length > 0}>
                <Button
                  disabled={skillChangesComplete}
                  variant="contained"
                  style={{ textTransform: "none", backgroundColor: "rgb(35, 57, 93)", color: "white" }}
                  onClick={cancelGroupAssignment}
                >
                  Finish
                </Button>
                <Button
                  disabled={!skillChangesComplete}
                  variant="contained"
                  style={{ textTransform: "none", marginLeft: 10 }}
                  onClick={() => {
                    setCancelMessage(
                      "Canceling will lose any selections made." +
                      "\n" +
                      "Would you like to cancel the process?"
                    );
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="status">
            <TaskStatus />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );

}



export default SkillResultsView