import config from '../config'

export const getParameterByName = (name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]")
    const regex = new RegExp("[\\#&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.hash)
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const fetchWrapper = async (url, init) => {
    const response = await fetch(url, init)
    const json = await response.json()
    return response.ok ? json : Promise.reject(json)
}

export const isIterable = (obj) => {
    // checks for null and undefined
    if (obj == null) {
        return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
}

export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const capitalizeFirstLetter = (str) => {    
  return str[0].toUpperCase() + str.slice(1); 
}

export default {
    isIterable,
    getParameterByName,
    fetchWrapper,
    sleep
}