import React, { Fragment, useState } from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from "@material-ui/styles"
import './FiltersSelector.css'

const filtersBy = [
    { id: 'members', label: 'Members' },
    { id: 'groups', label: 'Groups' },
    { id: 'divisions', label: 'Divisions' },
    { id: 'locations', label: 'Locations' },
    { id: 'reportsto', label: 'Reports To' },
    { id: 'roles', label: 'Roles' },
]

const FiltersSelector = (props) => {
    const { members, groups, divisions, locations, reportsTo, roles, selectedFiltersChanged, styles } = props
    const [filterByValue, setFilterByValue] = useState(filtersBy[0])
    const [filters, setFilters] = useState([])
    const [dataSource, setDataSource] = useState(members)
    const useStyles = makeStyles(styles)
    const classes = useStyles();
    const filterByOnChange = (event, value) => {
        setFilterByValue(value)
        setFilters([])
        console.log('value:', value)
        selectedFiltersChanged('all',[])
        const filterId = filtersBy.find(x => x.id === value?.id).id
        switch (filterId) {
            case 'groups': setDataSource(groups)
                break;
            case 'divisions': setDataSource(divisions)
                break;
            case 'locations': setDataSource(locations)
                break;
            case 'reportsto':  setDataSource(reportsTo)
                break;
            case 'roles': setDataSource(roles)
                break;
            default: setDataSource(members)
                break;
        }
    }

    const selectedFiltersOnChange = (event, value) => {
        setFilters(value)
        selectedFiltersChanged(filterByValue.id, value)
    }

    return (
        <Fragment>
            <Autocomplete className={classes.root} style={{ marginTop: "5px"}}
                id="combo-box-filters"
                value={filterByValue}
                onChange={(event, newValue) => { filterByOnChange(event, newValue) }}
                options={filtersBy}
                getOptionLabel={(option) => option.label}
                disableClearable
                renderInput={(params) => <TextField {...params} label="Filter By" variant="outlined" size="small" />}
            />
            <Autocomplete style={{width:"100%", marginTop: "10px", marginBottom: "5px"}}
                multiple
                limitTags={4}
                id="combo-box-entities-search"
                value={filters}
                onChange={(event, newValue) => { selectedFiltersOnChange(event, newValue) }}
                options={dataSource}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Search" variant="outlined" size="small"/>}
            />
        </Fragment>
    )
}

export default FiltersSelector