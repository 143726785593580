import { getAllLanguagesFromGC, getAllSkillsFromGC, getDivisionsFromGC, getDynamicGroupsFromGC, getGroupId, getGroupUsers, getGroupsFromGC, getLocationsFromGC, getRolesFromGC } from "./purecloud";
import utils from "./utils";
import backendAPI from "../services/backend";

export const getGroups = async (token, env) => {
    let allGroupsRetrieved = false;
    let pageNumber = 1;
    let groupResult = [];
    let groupResponse = [];
    while (!allGroupsRetrieved) {
        try {
            const getGroupResponse = await getGroupsFromGC(env, token, 500, pageNumber);
            if (
                getGroupResponse &&
                getGroupResponse.entities &&
                getGroupResponse.entities.length > 0
            ) {
                groupResult = groupResult.concat(getGroupResponse.entities);
                pageNumber++;
            } else {
                allGroupsRetrieved = true;
            }
        } catch (error) {
            console.error(`Exception occurred while retreiving static groups, Error Message : ${error.message}`);
            if (error.status === 429) {
                console.debug(`GC RateLimit Exception occurred and the retryAfterMs value received as : ${error.retryAfterMs}`);
                await utils.sleep(error.retryAfterMs);
                console.debug(`RateLimit handling - waited for ${error.retryAfterMs} ms`);
            } else throw error;
        }
    }
    groupResult?.forEach((group) => {
        if (group?.state == "active") {
            groupResponse.push({ id: group.id, name: group.name });
        }
    });
    //console.log("group", groupResponse);
    return groupResponse;
}

export const getUsers = async (token, env) => {
    //fetch the groupID
    const groupSearchResponse = await getGroupId(env, token, JSON.stringify
        ({
            "pageNumber": 1,
            "pageSize": 25,
            "query": [
                { "type": "EXACT", "value": "active", "fields": ["state"], "operator": "AND" },
                { "type": "EXACT", "value": "Bulk Skills Administration Users", "fields": ["name"] }]
        })
    );
    // console.info("Group Details", JSON.stringify(groupSearchResponse))


    const groupId = groupSearchResponse.results[0].id

    //fetch the group members
    let allUsersRetrieved = false;
    let pageNumber = 1;
    let userResult = [];
    let userResponse = [];
    while (!allUsersRetrieved) {
        let isRateLimitExceed = false;
        //console.log("isRateLimitExceed is set to false");
        try {
            //console.log("Get All users api called");
            //console.log("isRateLimitExceed: " + isRateLimitExceed);
            const getUserResponse = await getGroupUsers(env, token, groupId, 300, pageNumber, ["groups,locations,authorization,skills,languages"]
            );

            //console.log(getUserResponse)

            if (
                getUserResponse &&
                getUserResponse.entities &&
                getUserResponse.entities.length > 0
            ) {
                //console.log("User count: " + getUserResponse.entities.length);
                userResult = userResult.concat(getUserResponse.entities);
                pageNumber++;
            } else allUsersRetrieved = true;
            //console.log("No 429 error occured");
        } catch (error) {
            if (error.status === 429) {
                //console.log("error status: " + error.status);
                //console.log("isRateLimitExceed is set to true");
                isRateLimitExceed = true;
                //console.log("Request set to sleep for: " + error.retryAfterMs);
                await utils.sleep(error.retryAfterMs);
            } else {
                //console.log("Error other than 429. Error message:" + error);
                throw error;
            }
        } finally {
            //console.log("isRateLimitExceed: " + isRateLimitExceed);
            if (isRateLimitExceed) {
                //console.log("Request reset to call Get User API");
                allUsersRetrieved = false;
            }
        }
    }


    //console.log("All user count: " + userResult.length);
    //console.log("Foreach starts");
    userResult.forEach((user) => {
        let division = [];
        division.push({ id: user.division.id, name: user.division.name });

        let mangerInfo = [];
        if (user.manager != undefined) {
            mangerInfo.push({ id: user.manager.id });
        }
        let userGroups = [];
        if (user.groups != undefined && user.groups.length > 0) {
            user.groups.forEach((group) => {
                userGroups.push({ id: group.id });
            });
        }

        let userSkills = [];
        if (user.skills != undefined && user.skills.length > 0) {
            user.skills.forEach((skill) => {
                if (skill.state == "active") {
                    userSkills.push({
                        id: skill.id,
                        name: skill.name,
                        ratings: skill.proficiency,
                    });
                }
            });
        }

        let userLanguages = [];
        if (user.languages != undefined && user.languages.length > 0) {
            user.languages.forEach((language) => {
                if (language.state == "active") {
                    userLanguages.push({
                        id: language.id,
                        name: language.name,
                        ratings: language.proficiency,
                    });
                }
            });
        }

        let userRoles = [];
        if (
            user.authorization != undefined &&
            user.authorization.roles != undefined &&
            user.authorization.roles.length > 0
        ) {
            user.authorization.roles.forEach((role) => {
                userRoles.push({ id: role.id, name: role.name });
            });
        }

        let userLocations = [];

        if (user.locations != undefined && user.locations.length > 0) {
            user.locations.forEach((location) => {
                userLocations.push({
                    id: location.locationDefinition.id,
                });
            });
        }
        userResponse.push({
            id: user.id,
            name: user.name,
            email: user.email,
            skills: userSkills,
            languages: userLanguages,
            groups: userGroups,
            divisions: division,
            reportsTo: mangerInfo,
            roles: userRoles,
            locations: userLocations,
        });
    });
    //console.log("users ", userResponse);
    return userResponse;
}


export const getLocations = async (token, env) => {
    let allLocationsRetrieved = false;
    let pageNumber = 1;
    let locationResult = [];
    let locationResponse = [];
    while (!allLocationsRetrieved) {
        try {
            const getLocationResponse = await getLocationsFromGC(env, token, 500, pageNumber);
            if (
                getLocationResponse &&
                getLocationResponse.entities &&
                getLocationResponse.entities.length > 0
            ) {
                locationResult = locationResult.concat(getLocationResponse.entities);
                pageNumber++;
            } else {
                allLocationsRetrieved = true;
            }
        } catch (error) {
            console.error(`Exception occurred while retreiving locations, Error Message : ${error.message}`);
            if (error.status === 429) {
                console.debug(`GC RateLimit Exception occurred and the retryAfterMs value received as : ${error.retryAfterMs}`);
                await utils.sleep(error.retryAfterMs);
                console.debug(`RateLimit handling - waited for ${error.retryAfterMs} ms`);
            } else throw error;
        }
    }
    locationResult.forEach((group) => {
        if (group.state == "active") {
            locationResponse.push({ id: group.id, name: group.name });
        }
    });
    //console.log(" location response", locationResponse);
    return locationResponse;
}

export const getRoles = async (token, env) => {
    let allRoleRetrieved = false;
    let pageNumber = 1;
    let roleResult = [];
    let roleResponse = [];
    while (!allRoleRetrieved) {
        try {
            const getRoleResponse = await getRolesFromGC(env, token, 500, pageNumber)
            if (
                getRoleResponse &&
                getRoleResponse.entities &&
                getRoleResponse.entities.length > 0
            ) {
                roleResult = roleResult.concat(getRoleResponse.entities);
                pageNumber++;
            } else allRoleRetrieved = true;
        } catch (error) {
            console.error(`Exception occurred while retreiving roles, Error Message : ${error.message}`);
            if (error.status === 429) {
                console.debug(`GC RateLimit Exception occurred and the retryAfterMs value received as : ${error.retryAfterMs}`);
                await utils.sleep(error.retryAfterMs);
                console.debug(`RateLimit handling - waited for ${error.retryAfterMs} ms`);
            } else throw error;
        }
    }
    roleResult?.forEach((role) => {
        roleResponse.push({ id: role.id, name: role.name });
    });
    //console.log("role response", roleResponse)
    return roleResponse;
}

export const getDivisions = async (token, env) => {

    let allDivisionRetrieved = false;
    let pageNumber = 1;
    let divisionResult = [];
    let divisionResponse = [];
    while (!allDivisionRetrieved) {
        try {
            const getDivisionResponse = await getDivisionsFromGC(env, token, 300, pageNumber);
            if (
                getDivisionResponse &&
                getDivisionResponse.entities &&
                getDivisionResponse.entities.length > 0
            ) {
                divisionResult = divisionResult.concat(getDivisionResponse.entities);
                pageNumber++;
            } else allDivisionRetrieved = true;
        } catch (error) {
            console.error(`Exception occurred while retreiving divisions, Error Message : ${error.message}`);
            if (error.status === 429) {
                console.debug(`GC RateLimit Exception occurred and the retryAfterMs value received as : ${error.retryAfterMs}`);
                await utils.sleep(error.retryAfterMs);
                console.debug(`RateLimit handling - waited for ${error.retryAfterMs} ms`);
            } else throw error;
        }
    }
    divisionResult?.forEach((division) => {
        divisionResponse.push({ id: division.id, name: division.name });
    });
    //console.log("divisionResponse", divisionResponse)
    return divisionResponse;
}

export const getDynamicGroups = async (token, env) => {

    let allGroupsRetrieved = false;
    let groupResult = [];
    let apiURL = null;
    while (!allGroupsRetrieved) {
        try {
            const getGroupResponse = await getDynamicGroupsFromGC(env, token, apiURL);
            if (getGroupResponse && getGroupResponse.entities && getGroupResponse.entities.length > 0) {
                groupResult = groupResult.concat(getGroupResponse.entities);
                if (getGroupResponse.nextUri) {
                    apiURL = getGroupResponse.nextUri;
                } else {
                    allGroupsRetrieved = true;
                }
            } else allGroupsRetrieved = true;
        } catch (error) {
            console.error(`Exception occurred while retreiving dynamic groups, Error Message : ${error.message}`);
            if (error.status === 429) {
                console.debug(`GC RateLimit Exception occurred and the retryAfterMs value received as : ${error.retryAfterMs}`);
                await utils.sleep(error.retryAfterMs);
                console.debug(`RateLimit handling - waited for ${error.retryAfterMs} ms`);
            } else throw error;
        }
    }


    let result = [];
    for (const group of groupResult) {
        result.push({ id: group.id, name: group.name, type: "dynamic" });
    }
    //console.log("dynamic groups", result);
    return result;
}

export const getTaskOperation = async (token, env) => {
    const response = await backendAPI.getTaskOperation(token, env);
    return response?.data?.Items ? response?.data?.Items : [];
}

export const getEntities = async (token, env) => {
    //Skill Business logic
    let entitiesResponse = [];
    let allSkillsRetrieved = false;
    let pageNumber = 1;
    let skillResult = [];
    while (!allSkillsRetrieved) {
        try {
            const getSkillResponse = await getAllSkillsFromGC(env, token, 300, pageNumber);
            if (
                getSkillResponse &&
                getSkillResponse.entities &&
                getSkillResponse.entities.length > 0
            ) {
                skillResult = skillResult.concat(getSkillResponse.entities);
                pageNumber++;
            } else allSkillsRetrieved = true;
        } catch (error) {
            console.error(`Exception occurred while retreiving all skills, Error Message : ${error.message}`);
            if (error.status === 429) {
                console.debug(`GC RateLimit Exception occurred and the retryAfterMs value received as : ${error.retryAfterMs}`);
                await utils.sleep(error.retryAfterMs);
                console.debug(`RateLimit handling - waited for ${error.retryAfterMs} ms`);
            } else throw error;
        }
    }

    //fetch the groupID
    const groupSearchResponse = await getGroupId(env, token, JSON.stringify
        ({
            "pageNumber": 1,
            "pageSize": 25,
            "query": [
                { "type": "EXACT", "value": "active", "fields": ["state"], "operator": "AND" },
                { "type": "EXACT", "value": "Bulk Skills Administration Users", "fields": ["name"] }]
        })
    );
    // console.info("Group Details", JSON.stringify(groupSearchResponse))
    if (!groupSearchResponse?.results) {
        return 'Group Not Found';
    }
    const groupId = groupSearchResponse.results[0].id

    let allUsersRetrieved = false;
    pageNumber = 1;
    let userResult = [];
    while (!allUsersRetrieved) {
        try {
            const getUserResponse = await getGroupUsers(env, token, groupId, 300, pageNumber, ["skills"]
            );
            if (
                getUserResponse &&
                getUserResponse.entities &&
                getUserResponse.entities.length > 0
            ) {
                userResult = userResult.concat(getUserResponse.entities);
                pageNumber++;
            } else allUsersRetrieved = true;
        } catch (error) {
            console.error(`Exception occurred while retreiving all users with skills, Error Message : ${error.message}`);
            if (error.status === 429) {
                console.debug(`GC RateLimit Exception occurred and the retryAfterMs value received as : ${error.retryAfterMs}`);
                await utils.sleep(error.retryAfterMs);
                console.debug(`RateLimit handling - waited for ${error.retryAfterMs} ms`);
            } else throw error;
        }
    }

    skillResult.forEach((skill, index) => {
        let skillMemberCount = 0;
        if (skill.state.toLowerCase() == "active") {
            userResult.forEach((user) => {
                user.skills.forEach((userSkill) => {
                    if (skill.id == userSkill.id) {
                        skillMemberCount++;
                    }
                });
            });
            entitiesResponse.push({
                id: skill.id,
                name: skill.name,
                type: "skill",
                memberCount: skillMemberCount,
            });
        }
    });

    let allLanguageRetrieved = false;
    pageNumber = 1;
    let languageResult = [];
    while (!allLanguageRetrieved) {
        try {
            const getLanguageResponse = await getAllLanguagesFromGC(env, token, 300, pageNumber);
            if (
                getLanguageResponse &&
                getLanguageResponse.entities &&
                getLanguageResponse.entities.length > 0
            ) {
                languageResult = languageResult.concat(getLanguageResponse.entities);
                pageNumber++;
            } else allLanguageRetrieved = true;
        } catch (error) {
            console.error(`Exception occurred while retreiving all languages, Error Message : ${error.message}`);
            if (error.status === 429) {
                console.debug(`GC RateLimit Exception occurred and the retryAfterMs value received as : ${error.retryAfterMs}`);
                await utils.sleep(error.retryAfterMs);
                console.debug(`RateLimit handling - waited for ${error.retryAfterMs} ms`);
            } else throw error;
        }
    }

    allUsersRetrieved = false;
    pageNumber = 1;
    userResult = [];
    while (!allUsersRetrieved) {
        try {
            const getUserResponse = await getGroupUsers(env, token, groupId, 300, pageNumber, ["languages"]
            );
            if (
                getUserResponse &&
                getUserResponse.entities &&
                getUserResponse.entities.length > 0
            ) {
                userResult = userResult.concat(getUserResponse.entities);
                pageNumber++;
            } else allUsersRetrieved = true;
        } catch (error) {
            console.error(`Exception occurred while retreiving all users with languages, Error Message : ${error.message}`);
            if (error.status === 429) {
                console.debug(`GC RateLimit Exception occurred and the retryAfterMs value received as : ${error.retryAfterMs}`);
                await utils.sleep(error.retryAfterMs);
                console.debug(`RateLimit handling - waited for ${error.retryAfterMs} ms`);
            } else throw error;
        }
    }

    languageResult.forEach((language, index) => {
        let languageMemberCount = 0;
        if (language.state.toLowerCase() == "active") {
            userResult.forEach((user) => {
                user.languages.forEach((userLanguage) => {
                    if (language.id == userLanguage.id) {
                        languageMemberCount++;
                    }
                });
            });
            entitiesResponse.push({
                id: language.id,
                name: language.name,
                type: "language",
                memberCount: languageMemberCount,
            });
        }
    });
    //console.log("entitiesResponse", entitiesResponse);
    return entitiesResponse;
}