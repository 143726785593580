const promiseReflect = require('promise-reflect')

export const fetchWrapper = async (url, init) => {
    const response = await fetch(url, init)
    let correlationId, retryAfterMs,json
    for (let pair of response.headers.entries()) {
        if (pair[0] === 'inin-correlation-id')
            correlationId = pair[1]

        if (pair[0] === 'retry-after')
            retryAfterMs = pair[1] * 1000
    }
   if (response.status !== 204) // Remove Language responds with 204 No Content
         json = await response.json()
    else
        json = {}
    
    return response.ok 
        ? { ...json, correlationId }
        : Promise.reject({ ...json, correlationId, retryAfterMs })

    // return response
}

export const isLoggedIn = () => {
    console.log('getting token', sessionStorage.getItem('purecloud-csp-token'))
    return sessionStorage.getItem('purecloud-csp-token') !== null
}

export const getOrganization = (environment, token) => {
    console.log(`getOrganization.env:${environment}, token:${token}`)
    return fetchWrapper(`https://api.${environment}/api/v2/organizations/me`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${token}`
        }
    })
}

export const addOrUpdateUserSkill = (token, env, userId, skillId, proficiency) => {
    return fetchWrapper(`https://api.${env}/api/v2/users/${userId}/routingskills`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${token}`
        },
        body: JSON.stringify({
            id: skillId,
            proficiency
        })
    })
        .catch(err => {
            throw {
                ...err,
                retry: {
                    userId,
                    skillId,
                    proficiency
                }
            }
        })
}

export const removeSkillFromUser = (token, env, userId, skillId) => {
    return fetchWrapper(`https://api.${env}/api/v2/users/${userId}/routingskills/${skillId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${token}`
        }
    }).catch(err => {
        throw {
            ...err,
            retry: {
                userId,
                skillId
            }
        }
    })
}

export const addOrUpdateUserLanguage = (token, env, userId, languageId, proficiency) => {
    return fetchWrapper(`https://api.${env}/api/v2/users/${userId}/routinglanguages`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${token}`
        },
        body: JSON.stringify({
            id: languageId,
            proficiency
        })
    }).catch(err => {
        throw {
            ...err,
            retry: {
                userId,
                languageId,
                proficiency
            }
        }
    })
}



export const bulkAddOrUpdateUserSkill = async (token, env, bulk) => {
    const promises = []
    for (const userUpdate of bulk) {
        promises.push(addOrUpdateUserSkill(token, env, userUpdate.userId, userUpdate.skillId, userUpdate.proficiency))
    }

    const promiseReflectResponse = await Promise.all(promises.map(promiseReflect))
    const resolvedPromiseResponse = promiseReflectResponse.filter(x => x.status === 'resolved').map(x => ({ ...x.data }))
  //  console.log(`resolvedPromiseResponse:${JSON.stringify(resolvedPromiseResponse)}`)
    const rejectedPromiseResponse = promiseReflectResponse.filter(x => x.status === 'rejected').map(x => ({ ...x.error }))
    console.log(`rejectedPromiseResponse:${JSON.stringify(rejectedPromiseResponse)}`)

    const nPromises = promises.length
    const nResolvedPromises = resolvedPromiseResponse.length
    const nRejectedPromises = rejectedPromiseResponse.length

    console.log('nPromises:', nPromises)
    console.log('nResolvedPromises:', nResolvedPromises)
    console.log('nRejectedPromises:', nRejectedPromises)
    const anyRetryAfter = nRejectedPromises && rejectedPromiseResponse.find(x => x.retryAfterMs) ? true : false
    return {
        nPromises,
        nResolvedPromises,
        nRejectedPromises,
        rejectedPromiseResponse,
        retryAfterMs: anyRetryAfter ? rejectedPromiseResponse.find(x => x.retryAfterMs).retryAfterMs : undefined
    }
}

export const bulkAddOrUpdateUserLanguage = async (token, env, bulk) => {
    const promises = []
    for (const userUpdate of bulk) {
        promises.push(addOrUpdateUserLanguage(token, env, userUpdate.userId, userUpdate.languageId, userUpdate.proficiency))
    }

    const promiseReflectResponse = await Promise.all(promises.map(promiseReflect))
    const resolvedPromiseResponse = promiseReflectResponse.filter(x => x.status === 'resolved').map(x => ({ ...x.data }))
  //  console.log(`resolvedPromiseResponse:${JSON.stringify(resolvedPromiseResponse)}`)
    const rejectedPromiseResponse = promiseReflectResponse.filter(x => x.status === 'rejected').map(x => ({ ...x.error }))
    console.log(`rejectedPromiseResponse:${JSON.stringify(rejectedPromiseResponse)}`)

    const nPromises = promises.length
    const nResolvedPromises = resolvedPromiseResponse.length
    const nRejectedPromises = rejectedPromiseResponse.length

    console.log('nPromises:', nPromises)
    console.log('nResolvedPromises:', nResolvedPromises)
    console.log('nRejectedPromises:', nRejectedPromises)

    const anyRetryAfter = nRejectedPromises && rejectedPromiseResponse.find(x => x.retryAfterMs) ? true : false
    return {
        nPromises,
        nResolvedPromises,
        nRejectedPromises,
        rejectedPromiseResponse,
        retryAfterMs: anyRetryAfter ? rejectedPromiseResponse.find(x => x.retryAfterMs).retryAfterMs : undefined
    }
}

export const bulkRemoveSkillFromUser = async (token, env, bulk) => {
    const promises = []
    for (const userUpdate of bulk) {
        promises.push(removeSkillFromUser(token, env, userUpdate.userId, userUpdate.skillId))
    }

    const promiseReflectResponse = await Promise.all(promises.map(promiseReflect))
    const resolvedPromiseResponse = promiseReflectResponse.filter(x => x.status === 'resolved').map(x => ({ ...x.data }))
  //  console.log(`resolvedPromiseResponse:${JSON.stringify(resolvedPromiseResponse)}`)
    const rejectedPromiseResponse = promiseReflectResponse.filter(x => x.status === 'rejected').map(x => ({ ...x.error }))
    console.log(`rejectedPromiseResponse:${JSON.stringify(rejectedPromiseResponse)}`)

    const nPromises = promises.length
    const nResolvedPromises = resolvedPromiseResponse.length
    const nRejectedPromises = rejectedPromiseResponse.length

    console.log('nPromises:', nPromises)
    console.log('nResolvedPromises:', nResolvedPromises)
    console.log('nRejectedPromises:', nRejectedPromises)
    const anyRetryAfter = nRejectedPromises && rejectedPromiseResponse.find(x => x.retryAfterMs) ? true : false
    return {
        nPromises,
        nResolvedPromises,
        nRejectedPromises,
        rejectedPromiseResponse,
        retryAfterMs: anyRetryAfter ? rejectedPromiseResponse.find(x => x.retryAfterMs).retryAfterMs : undefined
    }
}

export const bulkRemoveLanguageFromUser = async (token, env, bulk) => {
    
    const promises = []
    for (const userUpdate of bulk) {
        promises.push(removeLanguageFromUser(token, env, userUpdate.userId, userUpdate.languageId))
    }

    const promiseReflectResponse = await Promise.all(promises.map(promiseReflect))
    const resolvedPromiseResponse = promiseReflectResponse.filter(x => x.status === 'resolved').map(x => ({ ...x.data }))
   // console.log(`resolvedPromiseResponse:${JSON.stringify(resolvedPromiseResponse)}`)
    const rejectedPromiseResponse = promiseReflectResponse.filter(x => x.status === 'rejected').map(x => ({ ...x.error }))
    console.log(`rejectedPromiseResponse:${JSON.stringify(rejectedPromiseResponse)}`)

    const nPromises = promises.length
    const nResolvedPromises = resolvedPromiseResponse.length
    const nRejectedPromises = rejectedPromiseResponse.length

    console.log('nPromises:', nPromises)
    console.log('nResolvedPromises:', nResolvedPromises)
    console.log('nRejectedPromises:', nRejectedPromises)
    const anyRetryAfter = nRejectedPromises && rejectedPromiseResponse.find(x => x.retryAfterMs) ? true : false

    return {
        nPromises,
        nResolvedPromises,
        nRejectedPromises,
        rejectedPromiseResponse,
        retryAfterMs: anyRetryAfter ? rejectedPromiseResponse.find(x => x.retryAfterMs).retryAfterMs : undefined
    }
}


export const removeLanguageFromUser = (token, env, userId, languageId) => {
    return fetchWrapper(`https://api.${env}/api/v2/users/${userId}/routinglanguages/${languageId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${token}`
        }
    }).catch(err => {
        throw {
            ...err,
            retry: {
                userId,
                languageId
            }
        }
    })  
}

export const getMe = (token, env) => {
    return fetch(`https://api.${env}/api/v2/users/me?expand=authorization`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${token}`
        }
    }).then(response => response.json())
}

export const getGroupUsers = (env, token, groupId, pageSize, pageNumber, expand) => {
    return fetchWrapper(
      `https://api.${env}/api/v2/groups/${groupId}/members?pageSize=${pageSize}&pageNumber=${pageNumber}&expand=${expand}`,
      {
        method: "GET",
        origin: "*",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      }
    );
  };

export const getRolesFromGC = (env, token, pageSize, pageNumber) => {
    return fetchWrapper(
      `https://api.${env}/api/v2/authorization/roles?pageSize=${pageSize}&pageNumber=${pageNumber}&userCount=false`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      }
    );
  };

export const getGroupsFromGC = (env, token, pageSize, pageNumber) => {
    return fetchWrapper(
      `https://api.${env}/api/v2/groups?pageSize=${pageSize}&pageNumber=${pageNumber}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      }
    );
  };

export const getGroupId = (env, token, bodyContent) => {
  return fetchWrapper(`https://api.${env}/api/v2/groups/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
    body: bodyContent
  });
};

export const getLocationsFromGC = (env, token, pageSize, pageNumber) => {
    return fetchWrapper(
      `https://api.${env}/api/v2/locations?pageSize=${pageSize}&pageNumber=${pageNumber}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      }
    );
  };
  
export const getDivisionsFromGC = (env, token, pageSize, pageNumber) => {
    return fetchWrapper(
      `https://api.${env}/api/v2/authorization/divisions?pageSize=${pageSize}&pageNumber=${pageNumber}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      }
    );
  };

export const getDynamicGroupsFromGC = async(env, token, inputURL) => {
    let url = inputURL ? inputURL : '/api/v2/routing/skillgroups?pageSize=100';
    return await fetchWrapper(
      `https://api.${env}${url}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      }
    );
  };

  export const getAllSkillsFromGC = (env, token, pageSize, pageNumber) => {
    return fetchWrapper(
      `https://api.${env}/api/v2/routing/skills?pageSize=${pageSize}&pageNumber=${pageNumber}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      }
    );
  };

  export const getAllLanguagesFromGC = (env, token, pageSize, pageNumber) => {
    return fetchWrapper(
      `https://api.${env}/api/v2/routing/languages?pageSize=${pageSize}&pageNumber=${pageNumber}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      }
    );
  };

export default {
    isLoggedIn,
    fetchWrapper,
    getOrganization,
    addOrUpdateUserSkill,
    removeSkillFromUser,
    addOrUpdateUserLanguage,
    removeLanguageFromUser,
    bulkAddOrUpdateUserSkill,
    bulkAddOrUpdateUserLanguage,
    bulkRemoveSkillFromUser,
    bulkRemoveLanguageFromUser,
    getMe,
    getGroupUsers,
    getRolesFromGC,
    getGroupsFromGC,
    getGroupId,
    getLocationsFromGC,
    getDivisionsFromGC,
    getDynamicGroupsFromGC,
    getAllSkillsFromGC,
    getAllLanguagesFromGC
}
