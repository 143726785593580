import utils, { sleep } from './utils'
import config from '../config'
const { v1: uuidv1 } = require('uuid')
function checkStatus(response) {
  if (!response.ok) {
    throw new Error(`HTTP ${response.status} - ${response.statusText}`);
  }
  return response;
}

export const addGroup = async (token, env, addUsers) => {
  const uri = `${config.endpoints.backend}/add/group`;
  return utils.fetchWrapper(uri, {
    method: "POST",
    headers: {
      env: env,
      token: token,
      tokensource: "purecloud",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      addUsers: addUsers,
    }),
  });
};

// TODO: Clarify All URI resources with backend dev
export const getEntities = async (token, env) => {
  const uri = `${config.endpoints.backend}/entities`;
  return utils.fetchWrapper(uri, {
    method: "GET",
    headers: {
      env: env,
      token: token,
      tokensource: "purecloud",
      "Content-Type": "application/json",
    },
  });
};

export const addEntity = async (token, env, entity) => {
  const uri = `${config.endpoints.backend}/entities/?type=${entity.skilltype}`;
  //console.log(uri, token, env, entity )
  return utils.fetchWrapper(uri, {
    method: "POST",
    headers: {
      env: env,
      token: token,
      tokensource: "purecloud",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: entity.skillname,
    }),
  });
};

export const deleteEntity = async (token, env, entity) => {
  const uri = `${config.endpoints.backend}/entities/${entity.entityID}/?type=${entity.skilltype}`;
  //console.log(uri, entity )
  return utils.fetchWrapper(uri, {
    method: "DELETE",
    headers: {
      env: env,
      token: token,
      tokensource: "purecloud",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: entity.skillname,
    }),
  });
};

export const editEntity = async (token, env, entity) => {
  const uri = `${config.endpoints.backend}/entities/${entity.entityID}/?type=${entity.skilltype}`;
  //console.log(uri, token, env, entity )
  return utils.fetchWrapper(uri, {
    method: "PUT",
    headers: {
      env: env,
      token: token,
      tokensource: "purecloud",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: entity.skillname,
    }),
  });
};

export const getUsers = async (token, env) => {
  const uri = `${config.endpoints.backend}/users`;
  return utils
    .fetchWrapper(uri, {
      method: "GET",
      headers: {
        env,
        token,
        tokensource: "purecloud",
        "Content-Type": "application/json",
      },
    })
    .then((json) => ({ ...json, id: "users" }));
};

export const getDivisions = async (token, env) => {
  const uri = `${config.endpoints.backend}/divisions`;
  return utils
    .fetchWrapper(uri, {
      method: "GET",
      headers: {
        env,
        token,
        tokensource: "purecloud",
        "Content-Type": "application/json",
      },
    })
    .then((json) => ({ ...json, id: "divisions" }));
};
export const getGroups = async (token, env) => {
  const uri = `${config.endpoints.backend}/groups`;
  return utils
    .fetchWrapper(uri, {
      method: "GET",
      headers: {
        env,
        token,
        tokensource: "purecloud",
        "Content-Type": "application/json",
      },
    })
    .then((json) => ({ ...json, id: "groups" }));
};
export const getDynamicGroups = async (token, env) => {
  const uri = `${config.endpoints.backend}/dynamicgroups`;
  return utils
    .fetchWrapper(uri, {
      method: "GET",
      headers: {
        env,
        token,
        tokensource: "purecloud",
        "Content-Type": "application/json",
      },
    })
    .then((json) => ({ ...json, id: "dynamicgroups" }));
};
export const getRoles = async (token, env) => {
  const uri = `${config.endpoints.backend}/roles`;
  return utils
    .fetchWrapper(uri, {
      method: "GET",
      headers: {
        env,
        token,
        tokensource: "purecloud",
        "Content-Type": "application/json",
      },
    })
    .then((json) => ({ ...json, id: "roles" }));
};
export const getLocations = async (token, env) => {
  const uri = `${config.endpoints.backend}/locations`;
  return utils
    .fetchWrapper(uri, {
      method: "GET",
      headers: {
        env,
        token,
        tokensource: "purecloud",
        "Content-Type": "application/json",
      },
    })
    .then((json) => ({ ...json, id: "locations" }));
};

export const validateOrg = async (token, env) => {
  const uri = `${config.endpoints.backend}/validateOrg`;
  return utils
    .fetchWrapper(uri, {
      method: "GET",
      headers: {
        env,
        token,
        tokensource: "purecloud",
        "Content-Type": "application/json",
      },
    })
    .then((response) => response);
};

export const bulkAssignOrRemove = async (token, env, payload) => {
  const batchId = uuidv1();
  const uri = `${config.endpoints.backend}/createBatchProcess`;

  await utils.fetchWrapper(uri, {
    method: 'POST',
    headers: {
      env,
      token,
      tokensource: "purecloud",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...payload,
      bulk: payload?.bulk,
      totalCount: payload?.bulk.length,
      batchId: batchId,
    })
  });

  return batchId
};

const getTaskOperation = async (token, env) => {
  const uri = `${config.endpoints.backend}/getAllBatchStatus`;
  return await utils.fetchWrapper(uri, {
    method: "POST",
    headers: {
      env,
      token,
      tokensource: "purecloud",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  });
};

const getFailedBatchDetail = async (token, env, body) => {
  const uri = `${config.endpoints.backend}/getFailedBatchDetail`;
  return await utils.fetchWrapper(uri, {
    method: "POST",
    headers: {
      env,
      token,
      tokensource: "purecloud",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
};


export default {
  addGroup,
  getEntities,
  addEntity,
  editEntity,
  deleteEntity,
  getUsers,
  getDivisions,
  getGroups,
  getDynamicGroups,
  getLocations,
  getRoles,
  validateOrg,
  getTaskOperation,
  getFailedBatchDetail,
  bulkAssignOrRemove
};
