import React from 'react';
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem

} from 'reactstrap'
import './Header.css'
import IconButton from '@material-ui/core/IconButton';
import HelpRounded from '@material-ui/icons/HelpRounded';
import { Link } from 'react-router-dom';

const Header = (props) => {
    return (
        <div>
            <Navbar dark expand="md" className="header">
                <Nav className="container-fluid" navbar>
                    <NavItem>
                        <NavbarBrand tag={Link} to="/skills/skillResults">Bulk Skills Administration</NavbarBrand>
                    </NavItem>
                    <NavItem>
                        Version 3.3
                        <IconButton aria-label="help" component={Link} to="/help" >
                            <HelpRounded style={{ color: "white" }} />
                        </IconButton>
                    </NavItem>
                </Nav>
            </Navbar>
        </div>
    );
}

export default Header;