import React, { Fragment, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from "@material-ui/styles";
import './GroupsSelector.css';

let filtersBy = []

const useCloseIconStyles = makeStyles({
    clearIndicator: {
        display: 'none',
    },
});

const GroupsSelector = (props) => {
    const addDuplicateKey = (data) => {
        const nameCountMap = new Map();

        data.forEach(obj => {
            const name = obj.name;
            if (nameCountMap.has(name)) {
                nameCountMap.set(name, 2);
            } else {
                nameCountMap.set(name, 1);
            }
        });

        data.forEach(obj => {
            const name = obj.name;
            obj.duplicate = nameCountMap.get(name) > 1;
        });

        return data;
    }

    const { allgroups, staticgroups, dynamicgroups, selectedFiltersChanged, styles, dynamicgroupsEnabled } = props
    const [allGroupList, setAllGroupList] = useState(addDuplicateKey(allgroups));

    if (dynamicgroupsEnabled) {
        filtersBy = [
            { id: 'allgroups', label: 'All Groups' },
            { id: 'staticgroups', label: 'Static Groups' },
            { id: 'dynamicgroups', label: 'Dynamic Groups' }
        ]
    }
    else {
        filtersBy = [
            { id: 'staticgroups', label: 'Static Groups' },
        ]
    }

    const [filterByValue, setFilterByValue] = useState(filtersBy[0])
    const [filters, setFilters] = useState([])
    const [dataSource, setDataSource] = useState(allGroupList)
    const useStyles = makeStyles(styles)
    const classes = useStyles();

    useEffect(() => {
        setAllGroupList(addDuplicateKey(allgroups));
    }, [allgroups]);

    const filterByOnChange = (event, value) => {
        setFilterByValue(value)
        setFilters([])
        selectedFiltersChanged('all', [])
        const filterId = filtersBy.find(x => x.id === value.id).id
        switch (filterId) {
            case 'allgroups': setDataSource(allGroupList)
                break;
            case 'staticgroups': setDataSource(staticgroups)
                break;
            case 'dynamicgroups': setDataSource(dynamicgroups)
                break;
            default: setDataSource(allgroups)
                break;
        }
    }

    const selectedFiltersOnChange = (event, value) => {
        setFilters(value)
        selectedFiltersChanged(filterByValue.id, value)
    }

    return (
        <Fragment>
            <Autocomplete className={classes.root} style={{ marginBottom: "10px" }}
                id="combo-box-filters"
                value={filterByValue}
                onChange={(event, newValue) => { filterByOnChange(event, newValue) }}
                options={filtersBy}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: null, // Removes the end adornment (clear icon)
                    }} label="Filter By" variant="outlined" size="small" />}
            />
            <Autocomplete className={classes.root}
                multiple
                limitTags={4}
                id="combo-box-entities-search"
                value={filters}
                onChange={(event, newValue) => { selectedFiltersOnChange(event, newValue) }}
                options={dataSource}
                getOptionLabel={(option) => option.name + (option.duplicate ? (option.type === 'dynamic' ? ' (Dynamic)' : ' (Static)') : '')}
                renderInput={(params) => <TextField {...params} label="Search" variant="outlined" size="small" />}
            />
        </Fragment>
    )
}

export default GroupsSelector