import React, { useState } from 'react';
import { FormControl, MenuItem, TextField, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Close from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import AddCircleIcon from '@material-ui/icons/AddCircle';


const SkillModal = (props) => {
    const { open, handleClose, modalTitle, contentAvailable, button1, button2, entityName, entityType, membersAssigned, handleSubmit, redirectUrl } = props
    const [skillNameEntered, setSkillNameEntered] = useState('')
    const [skillTypeSelected, setSkillTypeSelected] = useState('')
    const [errorName, setErrorName] = useState(false)
    const [errorType, setErrorType] = useState(false)

    const setData = async () => {
        const entityDetails = {};
        if (button1 === "Add" && (!skillNameEntered || !skillTypeSelected)) {
            console.log(skillNameEntered, skillTypeSelected, "skillNameEntered,skillTypeSelected")
            if (!skillNameEntered) setErrorName(true)
            if (!skillTypeSelected) setErrorType(true)
            return;
        }

        if (skillNameEntered)
            entityDetails['skillname'] = skillNameEntered
        else
            entityDetails['skillname'] = entityName

        if (skillTypeSelected)
            entityDetails['skilltype'] = skillTypeSelected.toLowerCase()
        else
            entityDetails['skilltype'] = entityType.toLowerCase()

        setErrorName(false)
        setErrorType(false)
        setSkillNameEntered('')
        setSkillTypeSelected('')
        handleSubmit(entityDetails)
    }

    const resetData = async () => {
        setErrorName(false)
        setErrorType(false)
        setSkillNameEntered('')
        setSkillTypeSelected('')
        handleClose()
    }

    return (
        <Dialog fullWidth maxWidth="sm" className="skill-modal animated fadeIn" onClose={resetData} open={open}>
            <DialogTitle id="alert-dialog-title" style={{ backgroundColor: "rgb(35, 57, 93)", color: "white" }}>
                <div className="d-flex justify-content-between align-items-center"> <span className="d-flex align-items-center">
                    {contentAvailable ? <AddCircleIcon style={{ marginRight: "5px" }} /> : <WarningIcon style={{ marginRight: "5px" }} />}
                    {modalTitle}</span>
                    <Button style={{ float: 'right', textTransform: 'none', backgroundColor: "rgb(35, 57, 93)", color: "white" }} onClick={resetData}> <Close /> </Button></div>
            </DialogTitle >
            {contentAvailable ? (
                <DialogContent dividers>
                    <FormControl style={{ width: "100%" }}>
                        <label> Name </label>
                        <TextField size="small" variant="outlined" placeholder="Enter a name"
                            error={errorName}
                            onChange={e => {
                                setSkillNameEntered(e.target.value)
                                setErrorName(false)
                            }} />
                        <br />
                        <label> Type </label>
                        <TextField variant="outlined" size="small" style={{ height: 40 }}
                            error={errorType}
                            select={true}
                            SelectProps={{
                                MenuProps: {
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }
                            }}
                            onChange={e => {
                                setSkillTypeSelected(e.target.value)
                                setErrorType(false)
                            }}
                        >
                            <MenuItem value="Skill">Skill</MenuItem>
                            <MenuItem value="Language">Language</MenuItem>
                        </TextField>
                    </FormControl>

                </DialogContent>
            ) : (<DialogContent dividers>
                <DialogContentText style={{ fontSize: 20 }}> There are <b>{membersAssigned}</b> member(s) assigned to this {entityType.toLowerCase()}</DialogContentText>
            </DialogContent>)}
            <DialogActions>
                <Button variant="contained" style={{ textTransform: 'none', backgroundColor: "rgb(35, 57, 93)", color: "white" }} onClick={setData}>{button1}</Button>
                <Button variant="contained" style={{ textTransform: 'none' }} onClick={resetData}>{button2}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SkillModal