import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import ReactDOM from 'react-dom'
import registerServiceWorker from './registerServiceWorker'
import AppRouter from './routers/AppRouter'

import { Provider } from "react-redux";
import store from './redux'
import Home from './components/home/Home'
ReactDOM.render(
  <Provider store={store}>
    <div id='bulk-skill-page'>
      <Home />
      <AppRouter />
    </div>
  </Provider>,
  document.getElementById('root')
);
registerServiceWorker()