import React from 'react';
import { Button } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import WarningIcon from '@material-ui/icons/Warning';
import DialogActions from '@material-ui/core/DialogActions';
import Close from '@material-ui/icons/Close';
import './ConfirmationModal.css'
import { useHistory } from 'react-router-dom'

const ConfirmationModal = (props) => {
    const history = useHistory();
    const { isOpen, header, body, button1, button2, button1Text, button2Text } = props
    return (
        <Dialog fullWidth className="confirmation-modal animated fadeIn" open={isOpen}>
            <DialogTitle className="confirmation-modal-header" style={{ backgroundColor: "rgb(35, 57, 93)", color: "white", textAlign: "center" }} >
                <div className="d-flex justify-content-between align-items-center"><span className="d-flex align-items-center">
                    {header == "Remove Skill" ? <WarningIcon style={{ marginRight: "5px" }} /> : <CheckCircle style={{ marginRight: "5px" }} />}
                    {header} </span>
                    <Button style={{ float: 'right', textTransform: 'none', backgroundColor: "rgb(35, 57, 93)", color: "white" }} onClick={() => button2()}> <Close /> </Button></div>
            </DialogTitle>
            <DialogContent> <DialogContentText style={{ fontSize: 20 }}> <div dangerouslySetInnerHTML={{ __html: body }} /></DialogContentText></DialogContent>
            <DialogActions>
                <div >
                    {button1 ? <Button style={{ backgroundColor: "rgb(35, 57, 93)", color: "white" }} onClick={button1}>{button1Text ? button1Text : 'Ok'}</Button> : null}
                </div>
                <div >
                    {button2 ? <Button onClick={() => button2()}>{button2Text ? button2Text : 'Cancel'}</Button> : null}
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationModal
