import React, { Fragment } from 'react'
import { handleRedirectFromLogin } from 'genesys-cloud-client-auth'

const PopUpLogin = () => {
  try {
    handleRedirectFromLogin();
    console.log('Successfully parsed params from hash');
  } catch (error) {
    console.warn(`Error parsing auth params from hash ${error.name}: "${error.message}"`);
  }
  return (<Fragment></Fragment>)
}

export default PopUpLogin;