import React from 'react';
import { BeatLoader } from 'react-spinners'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import './WaitingModal.css'

const WaitingModal = (props) => {
    const { isOpen, header = 'Loading' } = props
    return (
        <Dialog fullWidth maxWidth={"xs"} className="waiting-modal--centered animated fadeIn" open={isOpen}>
            <DialogTitle  className="waiting-modal--centered" style={{backgroundColor: "rgb(35, 57, 93)",color: "white", textAlign: "center"}}>{header}</DialogTitle>
            <DialogContent dividers className="waiting-modal--centered">
                <div className="waiting-modal__spinner">
                    <BeatLoader color="rgb(35, 57, 93)"/>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default WaitingModal;
