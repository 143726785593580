import React, { Fragment, useEffect, useState } from 'react'
import { Button, Drawer } from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid';
import { TextField, FormControl, MenuItem, Select } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Radio from '@mui/material/Radio';
import ConfirmationModal from '../modals/confirmationModal/ConfirmationModal';
import ProgressBarModal from '../modals/progressBarModal/ProgressBarModal'
import { bulkAssignOrRemove } from '../../services/backend';
import WaitingModal from '../modals/waitingModal/WaitingModal'
import AlertModal from '../modals/alertModal/AlertModal'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './RemoveSkillsDrawer.css'
import { useDispatch, useSelector } from 'react-redux';
import { setPullTaskStatus, setSaveBatchId } from '../../redux/orgData/orgDataSlice';


const RemoveSkillsDrawer = (props) => {
    const dispatch = useDispatch();
    const token = sessionStorage.getItem('purecloud-csp-token');
    const env = sessionStorage.getItem('purecloud-csp-env');
    const { open, toggle, anchor, selectedAgents, allSkillsLanguages, toggleCloseDrawer, modifiedAgentSkills, groupsUsers, enableFinishButton, setTabValue } = props
    const [pageSize, setPageSize] = useState(25);
    const [type, setType] = useState('All Selected');
    const [commonEntities, setCommonEntities] = useState(allSkillsLanguages);
    const [filterData, setfilterData] = useState(allSkillsLanguages);
    const [allEntities, setAllEntitites] = useState(allSkillsLanguages);
    const [filtertext, setFilterText] = useState('');
    const [message, setMessage] = useState();
    const [progressBarCurrent, setProgressBarCurrent] = useState(0);
    const [progressBarMax, setProgressBarMax] = useState(0);
    const [progressBarHeaderText, setProgressBarHeaderText] = useState();
    const [workInProgress, setWorkInProgress] = useState(false);
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false);
    const [waitingModalHeader, setWaitingModalHeader] = useState('');
    const [error, setError] = useState();
    const [membersToDelete, setMembersToDelete] = useState([]);
    const [noSkillSelected, setNoSkillSelected] = useState(true);
    const [height, setHeight] = useState("100vh");
    const usersData = useSelector((state) => state.orgData.orgData.users);
    const listOfSaveBtachIds = useSelector((state) => state.orgData.latestSavedBatchId);

    let radioChecked = [];
    const [nextButtonClicked, setNextButtonClicked] = useState(false);
    const [selectionModel, setSelectionModel] = React.useState(radioChecked);
    radioChecked = selectionModel;

    const selectedRow = filterData.filter((item) => {
        return item.id === selectionModel[0];
    });

    useEffect(() => {
        const handleResize = () => {
            const newHeight = document.documentElement.clientHeight;
            setHeight(`${newHeight - 150}px`);
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        try {
            const selectedSkillsLanguages = [];
            selectedAgents.forEach((agentId) => {
                const agent = usersData.find((user) => user.id === agentId);
                if (agent) {
                    selectedSkillsLanguages.push(...agent.skills, ...agent.languages);
                }
            });

            const uniqueIds = new Set(
                selectedSkillsLanguages.map((entity) => entity.id)
            );

            const selectedEntities = allEntities.filter((entity) =>
                uniqueIds.has(entity.id)
            );

            const selectedSkills = selectedEntities
                .filter((entity) => entity.type === "skill")
                .sort((a, b) => a.name.localeCompare(b.name));
            const selectedLanguages = selectedEntities
                .filter((entity) => entity.type === "language")
                .sort((a, b) => a.name.localeCompare(b.name));

            const filteredData = [...selectedSkills, ...selectedLanguages];
            setfilterData(filteredData);
            setCommonEntities(filteredData);
        } catch (error) {
            console.error("error:", error);
        }
    }, [allSkillsLanguages, selectedAgents, open]);

    console.log("RemoveSkillsDDrawer: Remove skills", filterData);

    const columns = [
        {
            field: "",
            headerName: "",
            width: 50,
            sortable: false,
            renderCell: (params) => (
                <Radio checked={radioChecked[0] === params.id} value={params.id} />
            )
        },
        {
            field: "name",
            headerName: "Name",
            sortable: false,
            resizable: false,
            minWidth: 100,
            flex: 0.65,
            type: 'string',
            headerClassName: 'super-app-theme--header'

        },
        {
            field: "type",
            headerName: "Type",
            sortable: false,
            resizable: false,
            minWidth: 100,
            flex: 0.35,
            type: 'string',
            headerClassName: 'super-app-theme--header'

        },
    ];

    useEffect(() => {
        if (type !== "All Selected") {
            setfilterData(commonEntities.filter(x => (x.name.toLowerCase().includes(filtertext.toLowerCase())) && (x.type === type.toLowerCase())))
        } else {
            setfilterData(commonEntities.filter(x => (x.name.toLowerCase().includes(filtertext.toLowerCase()))))
        }
    }, [allSkillsLanguages, selectedAgents, open])

    const handleCloseDrawer = () => {
        setSelectionModel([])
        setNextButtonClicked(false)
        toggleCloseDrawer(false)
        setType('All Selected')
        setfilterData(commonEntities)
        setNoSkillSelected(true)
        setFilterText('')
    }

    const cancelRemoveSkills = async () => {
        setMessage()
    }

    const waitPopup = async (title) => {
        const wait = () => new Promise((resolve) => setTimeout(resolve, 2000));
        setIsWaitingModalOpen(true)
        setWaitingModalHeader(title)
        await wait();
        setIsWaitingModalOpen(false)
        setWaitingModalHeader("")
    }

    const removeSkills = async () => {
        console.info("RemoveSKillsDrawer.RemoveSkills, Skill: ", selectedRow[0], "Users: ", selectedAgents, 'GroupMembers: ', groupsUsers)

        for (let i = 0; i < selectedAgents.length; i++) {
            membersToDelete.push({ userId: selectedAgents[i] })
        }

        setMessage()
        setNoSkillSelected(true)
        setWorkInProgress(true)
        setProgressBarHeaderText('Submitting request to Remove skill')

        let userListObject = {};
        if (usersData.length > 0) {
            usersData.forEach(element => {
                userListObject[element.id] = element;
            });
        }

        let bulkUpdate
        if (selectedRow[0].type === 'skill')
            bulkUpdate = membersToDelete.map(x => ({ userId: x.userId, skillId: selectedRow[0].id, username: userListObject[x.userId]?.name }));
        if (selectedRow[0].type === 'language')
            bulkUpdate = membersToDelete.map(x => ({ userId: x.userId, languageId: selectedRow[0].id, username: userListObject[x.userId]?.name }));

        if (bulkUpdate) {
            let nTotalUpdates = bulkUpdate.length
            setProgressBarMax(nTotalUpdates)

            const batchId = await bulkAssignOrRemove(token, env, {
                entityName: selectedRow[0].name,
                entityId: selectedRow[0].id,
                bulk: bulkUpdate,
                operation: 'Remove Users',
                type: selectedRow[0].type?.toLowerCase(),
                taskOwnerId: sessionStorage.getItem('userId'),
                taskOwnerName: sessionStorage.getItem('userName'),
                orgId: sessionStorage.getItem('orgId'),
            })

            await waitPopup("Request successfully submitted to Genesys Cloud");
            await waitPopup("Navigating to Task Tracker page to check status");
            setProgressBarCurrent(0)
            setProgressBarMax(0)
            setWorkInProgress(false)
            setNextButtonClicked(false)
            setIsWaitingModalOpen(false)
            setSelectionModel([])
            dispatch(setPullTaskStatus({ isPullTaskStatus: true }))
            dispatch(setSaveBatchId({ latestSavedBatchId: [...listOfSaveBtachIds, ...[{ entityName: selectedRow[0].name, batchId: batchId }]] }))
            toggleCloseDrawer(false)
            enableFinishButton(true)
            setTabValue("status");
        }
    }

    const onClose = () => {
        handleCloseDrawer();
        toggle(false);
    }

    return (
        <Drawer open={open} anchor={anchor} onClose={onClose}>
            <ConfirmationModal
                isOpen={!!message}
                header='Remove Skill'
                body={message}
                button1={removeSkills}
                button2={cancelRemoveSkills}
                button1Text='Yes'
                button2Text='No'
            />
            <ProgressBarModal
                isOpen={workInProgress}
                header={progressBarHeaderText}
                progress={progressBarCurrent}
                min={0}
                max={progressBarMax}
            />
            <WaitingModal
                isOpen={isWaitingModalOpen}
                header={waitingModalHeader}
            />
            <AlertModal
                isOpen={!!error}
                header='Error'
                toggle={() => { setError(undefined) }}
                body={error}
            />
            <Fragment>
                <h5 style={{ marginLeft: 5 }}>Remove Skills and Languages</h5>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDrawer}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Fragment>
                    <div style={{ margin: "5px" }} className='d-flex justify-content-between'>
                        <FormControl variant="outlined" style={{ width: "34%" }}>
                            <TextField variant="outlined" size="small"
                                value={type}
                                select={true}
                                SelectProps={{
                                    MenuProps: {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }
                                }}
                                onChange={e => {
                                    setType(e.target.value)
                                    setfilterData(commonEntities.filter(x => (x.type === e.target.value.toLowerCase()) && (x.name.toLowerCase().includes(filtertext.toLowerCase()))))
                                    if (e.target.value === "All Selected") {
                                        setfilterData(commonEntities.filter(x => x.name.toLowerCase().includes(filtertext.toLowerCase())))
                                    }
                                }}>
                                <MenuItem value="All Selected" >All Selected</MenuItem>
                                <MenuItem value="Skill">Skill</MenuItem>
                                <MenuItem value="Language">Language</MenuItem>
                            </TextField>
                        </FormControl>
                        <TextField style={{ width: "65%" }}

                            InputProps={{
                                endAdornment: (
                                    <SearchIcon style={{ color: "#e0e0e0" }} />
                                )
                            }}
                            label="Search"
                            defaultValue=""
                            variant="outlined"
                            size="small"
                            onChange={e => {
                                setFilterText(e.target.value)
                                if (type !== "All Selected")
                                    setfilterData(commonEntities.filter(x => (x.name.toLowerCase().includes(e.target.value.toLowerCase())) && (x.type === type.toLowerCase())))
                                else
                                    setfilterData(commonEntities.filter(x => (x.name.toLowerCase().includes(e.target.value.toLowerCase()))))
                            }}
                        />
                    </div>
                    <div style={{ margin: "5px", height: height }}>
                        <DataGrid
                            rows={filterData}
                            columns={columns}
                            pageSize={pageSize}
                            rowsPerPageOptions={[25, 50, 75, 100]}
                            pagination
                            disableColumnFilter
                            disableColumnMenu
                            hideFooterSelectedRowCount
                            showColumnRightBorder
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            selectionModel={selectionModel}
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                                setNoSkillSelected(false)
                            }}
                        />
                    </div>
                    <div style={{ margin: "10px" }}>
                        <Button disabled={noSkillSelected} variant="contained" color='primary' style={{ textTransform: 'none', float: 'left', margin: 5, backgroundColor: "rgb(35, 57, 93)", color: "white" }} onClick={() => { setMessage("Remove the selected <b>" + selectedAgents.length + "</b> member(s) from Skill?") }}>Next</Button>
                        <Button variant="contained" style={{ textTransform: 'none', float: 'left', margin: 5 }} onClick={handleCloseDrawer}>Cancel</Button>
                    </div>
                </Fragment>
            </Fragment>
        </Drawer>
    )
}

export default RemoveSkillsDrawer