import React, { Fragment, useEffect, useState } from 'react';
import { Button, Checkbox, Drawer } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { DataGrid } from '@mui/x-data-grid';
import { TextField, FormControl, MenuItem } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from "@material-ui/styles";
import Radio from '@mui/material/Radio';
import ConfirmationModal from '../modals/confirmationModal/ConfirmationModal';
import ProgressBarModal from '../modals/progressBarModal/ProgressBarModal'
import { bulkAssignOrRemove } from '../../services/backend';
import WaitingModal from '../modals/waitingModal/WaitingModal'
import AlertModal from '../modals/alertModal/AlertModal'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './AddSkillsDrawer.css';
import { useDispatch, useSelector } from 'react-redux';
import { setPullTaskStatus, setSaveBatchId } from '../../redux/orgData/orgDataSlice';
import { capitalizeFirstLetter } from '../../services/utils';

const AddSkillsDrawer = (props) => {
    const dispatch = useDispatch();
    const token = sessionStorage.getItem('purecloud-csp-token')
    const env = sessionStorage.getItem('purecloud-csp-env')
    const { open, toggle, anchor, selectedAgents, allSkillsLanguages, toggleCloseDrawer, entityName, entityID, modifiedAgentSkills, groupsUsers, enableFinishButton, setTabValue } = props
    const [pageSize, setPageSize] = useState(25)
    const [type, setType] = useState('All Selected')
    const [filteredData, setfilteredData] = useState([allSkillsLanguages])
    const [sortedEntities, setSortedEntities] = useState(allSkillsLanguages)

    const [allEntities, setAllEntitites] = useState(allSkillsLanguages)
    const [filtertext, setFilterText] = useState('')
    const [selectedSkilledUsers, setSelectedSkilledUsers] = useState([])
    const [allSelectedSkilledUsers, setAllSelectedSkilledUsers] = useState([])
    const [editedRatingUsers, setEditedRatingUsers] = useState([])
    const [selectedUsersforSkillEdit, setSelectedUsersforSkillEdit] = useState([])
    const [message, setMessage] = useState()
    const [hiddenUpdateRating, setHiddenUpdateRating] = useState(true)
    const [progressBarCurrent, setProgressBarCurrent] = useState(0)
    const [progressBarMax, setProgressBarMax] = useState(0)
    const [value, setValue] = useState(0)
    const [skillSelected, setSkillSelected] = useState(true)
    const [sameRatingEnabled, setSameRatingEnabled] = useState(false)
    const [progressBarHeaderText, setProgressBarHeaderText] = useState()
    const [disableRating, setDisableRating] = useState(true)
    const [checkBoxSet, setCheckBoxSet] = useState(false)
    const [workInProgress, setWorkInProgress] = useState(false)
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)
    const [waitingModalHeader, setWaitingModalHeader] = useState('')
    const [error, setError] = useState()
    const usersData = useSelector((state) => state.orgData.orgData.users);
    const listOfSaveBtachIds = useSelector((state) => state.orgData.latestSavedBatchId);
    const [memberFilter, setMemberFilter] = useState();
    const [hiddenButtons, setHiddenButtons] = useState(true);

    const styles = {
        root: {
            display: "flex",
            flexDirection: 'column',
        }
    }

    const useStyles = makeStyles(styles)
    const classes = useStyles();
    let radioChecked = [];
    const [nextButtonClicked, setNextButtonClicked] = useState(false)
    const [selectionModel, setSelectionModel] = React.useState(radioChecked);
    radioChecked = selectionModel;
    const selectedRow = filteredData.filter((item) => {
        return item.id === selectionModel[0];
    });

    console.log("Add skills Drawer Component", allSkillsLanguages)
    const [height, setHeight] = useState("100vh");

    useEffect(() => {
        const handleResize = () => {
            const newHeight = document.documentElement.clientHeight;
            setHeight(`${newHeight - 150}px`);
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        console.log("Add skills Drawer")
        try {
            const userSet = {};
            selectedAgents.forEach(agentId => {
                if (usersData.hasOwnProperty(agentId)) {
                    const { skills, languages } = usersData[agentId];
                    skills.forEach(skill => {
                        if (!userSet[skill.id]) {
                            userSet[skill.id] = skill;
                        }
                    });
                    languages.forEach(language => {
                        if (!userSet[language.id]) {
                            userSet[language.id] = language;
                        }
                    });
                }
            });

            const selectedMembersEntities = Object.values(userSet);
            const selectedMembersSkills = selectedMembersEntities.filter(entity => entity.type === "skill").sort((a, b) => a.name.localeCompare(b.name));
            const selectedMembersLanguages = selectedMembersEntities.filter(entity => entity.type === "language").sort((a, b) => a.name.localeCompare(b.name));

            const all = [...selectedMembersSkills, ...selectedMembersLanguages, ...allEntities];
            const sortedEntities = removeDuplicates(all);

            console.info('AddSkillDrawer: Sorted entities, length:', sortedEntities, sortedEntities.length);
            setfilteredData(sortedEntities);
            setSortedEntities(sortedEntities);
        } catch (error) {
            console.error('error:', error);
        }
    }, [allEntities, usersData])

    useEffect(() => {
        setAllEntitites(props.allSkillsLanguages);
    }, [props.allSkillsLanguages])

    useEffect(() => {
        setSelectedSkilledUsers(allSelectedSkilledUsers.filter(x => (x.name.toLowerCase().includes(memberFilter?.toLowerCase()))))
    }, [memberFilter])


    const removeDuplicates = (array) => {
        let jsonObject = array.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        return (uniqueArray);
    }

    const RatingEditInputCell = (props) => {
        const { id, value, api, field } = props;

        const handleChange = (event) => {
            api.setEditCellValue(
                { id, field, value: Number(event.target.value) },
                event
            );
            if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
                api.commitCellChange({ id, field });
                api.setCellMode(id, field, "view");
            }
            if (JSON.stringify(editedRatingUsers).includes(id)) {
                editedRatingUsers.forEach(element => {
                    if (element.userId === id)
                        element.value = Number(event.target.value)
                })
            }
            else
                setEditedRatingUsers([...editedRatingUsers, { userId: id, value: Number(event.target.value) }])
        };


        return (
            <div className={classes.root}>
                <Rating
                    name="rating"
                    precision={1}
                    value={Number(value)}
                    onChange={handleChange}
                    size={'large'}
                    style={{ color: "#444a52" }}
                />
            </div>
        );
    }
    const tableRowSelected = (params) => {
        //after selecting common rating, if user removes any selected agent
        if (value > 0) {
            if (params.length < selectedUsersforSkillEdit.length) {
                let difference = selectedUsersforSkillEdit.filter(x => !params.includes(x));
                console.debug("AddSKillsDrawer: Difference:", difference, value)
                for (let i = 0; i < difference.length; i++) {
                    if (editedRatingUsers.some(x => x.userId === difference[i])) {
                        if (editedRatingUsers.find(x => x.userId === difference[i]).value === value)
                            editedRatingUsers.splice(editedRatingUsers.findIndex(a => a.userId === difference[i]), 1)
                    }
                }


            }
            //after selecting common rating, if user selects any extra agent
            if (params.length > selectedUsersforSkillEdit.length) {
                let difference = params.filter(x => !selectedUsersforSkillEdit.includes(x));
                console.debug("AddSKillsDrawer: Difference:", difference, value)
                editedRatingUsers.push({ userId: difference[0], value: value })
            }
        }
        setSelectedUsersforSkillEdit(params)
        console.info("AddSKillsDrawer: SelectedRow", params, "SelectedUsers", selectedUsersforSkillEdit)
        console.info("AddSKillsDrawer: Edited Users", editedRatingUsers)
        if (params.length > 0) {
            setHiddenUpdateRating(false)
        }
        else {
            setHiddenUpdateRating(true)
            setValue(0)
            setSameRatingEnabled(false)
            setCheckBoxSet(false)
        }
        if (sameRatingEnabled === true)
            setDisableRating(false)
        else
            setDisableRating(true)
    }

    const columns = [
        {
            field: "",
            headerName: "",
            width: 50,
            sortable: false,
            renderCell: (params) => (
                <Radio checked={radioChecked[0] === params.id} value={params.id} />
            )
        },
        {
            field: "name",
            headerName: "Name",
            sortable: false,
            resizable: false,
            minWidth: 100,
            flex: 0.65,
            type: 'string',
            headerClassName: 'super-app-theme--header'

        },
        {
            field: "type",
            headerName: "Type",
            sortable: false,
            resizable: false,
            minWidth: 100,
            flex: 0.35,
            type: 'string',
            headerClassName: 'super-app-theme--header'

        },
    ];



    const columnsUsers = [
        {
            field: "name",
            headerName: "Name",
            sortable: false,
            resizable: false,
            minWidth: 100,
            flex: 0.65,
            type: 'string',
            headerClassName: 'super-app-theme--header'

        },
        {
            field: "proficiency",
            headerName: "Rating",
            sortable: false,
            resizable: false,
            minWidth: 100,
            flex: 0.5,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div className={classes.root}>
                    <Rating readOnly value={params.value} size={'large'} style={{ color: "#444a52" }} />
                </div>),
            renderEditCell: RatingEditInputCell,
            editable: true
        },
    ];

    const handleCloseDrawer = () => {
        console.log('handle close');
        setSelectionModel([])
        setNextButtonClicked(false)
        toggleCloseDrawer(false)
        setType('All Selected')
        setfilteredData(sortedEntities)
        setSkillSelected(true)
        setFilterText('')
    }

    const selectRatingForSkills = () => {

        setNextButtonClicked(true)
        let users = JSON.parse(JSON.stringify(usersData));
        let skillUsers = []
        for (let i = 0; i < selectedAgents.length; i++) {
            skillUsers = [...skillUsers, users.find(x => x.id === selectedAgents[i])]
        }

        for (let i = 0; i < skillUsers.length; i++) {
            for (let j = 0; j < skillUsers[i].skills.length; j++) {
                if (skillUsers[i].skills[j].id === selectionModel[0])
                    skillUsers[i].proficiency = skillUsers[i].skills[j].ratings
            }
            for (let j = 0; j < skillUsers[i].languages.length; j++) {
                if (skillUsers[i].languages[j].id === selectionModel[0])
                    skillUsers[i].proficiency = skillUsers[i].languages[j].ratings
            }
        }
        console.log("AddSKillsDrawer: AssignedMembers:", selectedAgents, skillUsers)
        setSelectedSkilledUsers(skillUsers)
        setAllSelectedSkilledUsers(skillUsers)
    }

    const waitPopup = async (title) => {
        const wait = () => new Promise((resolve) => setTimeout(resolve, 2000));
        setIsWaitingModalOpen(true)
        setWaitingModalHeader(title)
        await wait();
        setIsWaitingModalOpen(false)
        setWaitingModalHeader("")
    }

    const assignSkills = async () => {
        setSkillSelected(true)
        setMessage()
        console.log("AddSKillsDrawer.AssignSkills: Skill:", selectedRow[0], "Users: ", editedRatingUsers)
        setWorkInProgress(true)
        setProgressBarHeaderText('Submitting request to update skill rating on Genesys Cloud')

        let userListObject = {};
        if (usersData.length > 0) {
            usersData.forEach(element => {
                userListObject[element.id] = element;
            });
        }

        let bulkUpdate = [];
        if (selectedRow[0].type === 'skill')
            bulkUpdate = editedRatingUsers.map(x => ({ userId: x.userId, proficiency: x.value, username: userListObject?.[x.userId]?.name, skillId: selectedRow[0].id }))
        if (selectedRow[0].type === 'language')
            bulkUpdate = editedRatingUsers.map(x => ({ userId: x.userId, proficiency: x.value, username: userListObject?.[x.userId]?.name, languageId: selectedRow[0].id }))


        if (bulkUpdate?.length > 0 && selectedRow?.[0]?.type) {
            let nTotalUpdates = bulkUpdate.length
            setProgressBarMax(nTotalUpdates)
            const batchId = await bulkAssignOrRemove(token, env, {
                entityName: selectedRow[0].name,
                entityId: selectedRow[0].id,
                bulk: bulkUpdate,
                operation: `Assign ${capitalizeFirstLetter(selectedRow[0].type)}`,
                type: selectedRow[0].type?.toLowerCase(),
                taskOwnerId: sessionStorage.getItem('userId'),
                taskOwnerName: sessionStorage.getItem('userName'),
                orgId: sessionStorage.getItem('orgId'),
            })

            setProgressBarHeaderText(`${editedRatingUsers.length} members successfully updated! Click Finish when skill modifications are complete`)
            setProgressBarCurrent(0)
            setProgressBarMax(0)
            setEditedRatingUsers([])
            setWorkInProgress(false)
            setNextButtonClicked(false)
            setSelectionModel([])
            setValue(0)
            setSameRatingEnabled(false)
            setCheckBoxSet(false)
            setHiddenUpdateRating(true)
            setIsWaitingModalOpen(false)
            await waitPopup("Request successfully submitted to Genesys Cloud");
            await waitPopup("Navigating to Task Tracker page to check status");
            dispatch(setPullTaskStatus({ isPullTaskStatus: true }))
            dispatch(setSaveBatchId({ latestSavedBatchId: [...listOfSaveBtachIds, ...[{ entityName: selectedRow[0].name, batchId: batchId }]] }))
        }
        toggleCloseDrawer(false)
        enableFinishButton(true)
        setTabValue("status");

    }
    const cancelAssignSkills = async () => {
        setMessage()
        setValue(0)
        setDisableRating(true)
        setCheckBoxSet(false)
        setEditedRatingUsers([])
        setSameRatingEnabled(false)
    }
    const handleSameRating = (event) => {

        setSameRatingEnabled(event.target.checked)
        if (event.target.checked === true) {
            setCheckBoxSet(true)
            setDisableRating(false)
        }
        else {
            setCheckBoxSet(false)
            setDisableRating(true)
            setValue(0)
            for (let i = 0; i < selectedUsersforSkillEdit.length; i++) {
                editedRatingUsers.splice(editedRatingUsers.find(x => x.userId === selectRatingForSkills[i]), 1)
            }
        }
    }

    useEffect(() => {
        if (type !== "All Selected")
            setfilteredData(sortedEntities.filter(x => (x.name.toLowerCase().includes(filtertext.toLowerCase())) && (x.type === type.toLowerCase())))
        else
            setfilteredData(sortedEntities.filter(x => (x.name.toLowerCase().includes(filtertext.toLowerCase()))))
    }, [sortedEntities, filtertext, type])

    const onClose = () => {
        handleCloseDrawer();
        toggle(false)
    }

    return (
        <Drawer open={open} anchor={anchor} onClose={onClose}>
            <ConfirmationModal
                isOpen={!!message}
                header='Update Skill'
                body={message}
                button1={assignSkills}
                button2={cancelAssignSkills}
                button1Text='Yes'
                button2Text='No'
            />
            <ProgressBarModal
                isOpen={workInProgress}
                header={progressBarHeaderText}
                progress={progressBarCurrent}
                min={0}
                max={progressBarMax}
            />
            <WaitingModal
                isOpen={isWaitingModalOpen}
                header={waitingModalHeader}
            />
            <AlertModal
                isOpen={!!error}
                header='Error'
                toggle={() => { setError(undefined) }}
                body={error}
            />

            <Fragment>
                <h5 style={{ marginLeft: 5, fontWeight: '700' }}>Assign Skills and Languages</h5>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDrawer}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {nextButtonClicked === false &&
                    <Fragment>
                        <div style={{
                            margin: "5px",
                        }} className='d-flex justify-content-between'>
                            <div style={{ width: "35%" }} >
                                <FormControl variant="outlined" >
                                    <TextField variant="outlined" size="small" style={{ minWidth: 150, width: "80%" }}
                                        value={type}
                                        select={true}
                                        SelectProps={{
                                            MenuProps: {
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null
                                            }
                                        }}
                                        onChange={e => {
                                            setType(e.target.value)
                                        }}>
                                        <MenuItem value="All Selected" >All Selected</MenuItem>
                                        <MenuItem value="Skill">Skill</MenuItem>
                                        <MenuItem value="Language">Language</MenuItem>
                                    </TextField>
                                </FormControl>
                            </div>

                            <div style={{ width: "65%" }}>
                                <TextField style={{ width: "100%" }}

                                    InputProps={{
                                        endAdornment: (
                                            <SearchIcon style={{ color: "#e0e0e0" }} />
                                        )
                                    }}
                                    label="Search"
                                    defaultValue=""
                                    variant="outlined"
                                    size="small"
                                    onChange={e => {
                                        setFilterText(e.target.value)
                                    }}
                                />
                            </div>
                        </div>

                        <div style={{ margin: "5px", height: height }}>
                            <DataGrid
                                rows={filteredData}
                                columns={columns}
                                pageSize={pageSize}
                                rowsPerPageOptions={[25, 50, 75, 100]}
                                pagination
                                disableColumnFilter
                                disableColumnMenu
                                hideFooterSelectedRowCount
                                showColumnRightBorder
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                selectionModel={selectionModel}
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                    setSkillSelected(false)
                                }}
                            />
                        </div>
                        <div>
                            <Button variant="contained" color='primary' disabled={skillSelected} style={{ textTransform: 'none', float: 'left', margin: 5, backgroundColor: "rgb(35, 57, 93)", color: "white" }} onClick={selectRatingForSkills}>Next</Button>
                            <Button variant="contained" style={{ textTransform: 'none', float: 'left', margin: 5 }} onClick={handleCloseDrawer}>Cancel</Button>
                        </div>
                    </Fragment>
                }

                {nextButtonClicked === true &&
                    <Fragment>
                        <TextField style={{
                            margin: "5px",
                        }} className='d-flex justify-content-between'

                            InputProps={{
                                endAdornment: (
                                    <SearchIcon style={{ color: "#e0e0e0" }} />
                                )
                            }}
                            label="Search"
                            defaultValue=""
                            variant="outlined"
                            size="small"
                            onChange={e => {
                                setMemberFilter(e.target.value)
                            }}
                        />
                        <div style={{ margin: "5px", height: height }}>
                            <DataGrid
                                rows={selectedSkilledUsers}
                                columns={columnsUsers}
                                pageSize={pageSize}
                                rowsPerPageOptions={[25, 50, 75, 100]}
                                pagination
                                disableColumnFilter
                                disableColumnMenu
                                hideFooterSelectedRowCount
                                showColumnRightBorder
                                checkboxSelection
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                onSelectionModelChange={tableRowSelected}
                            />
                        </div>
                        <div style={{ backgroundColor: "#ebf2f8" }}>
                            {!hiddenUpdateRating &&
                                <Fragment>
                                    <Checkbox checked={checkBoxSet} onChange={handleSameRating} color="primary"></Checkbox>
                                    <label> {`Apply the same rating for the ${selectedUsersforSkillEdit.length} selected members`}</label><br />
                                    <Rating
                                        name="simple-controlled"
                                        value={value}
                                        disabled={disableRating}
                                        onChange={(event, newValue) => {
                                            console.log('skillDetailsTable.newValue', newValue)
                                            if (newValue == null) {
                                                setValue(0)
                                                setEditedRatingUsers([])
                                                setHiddenButtons(true)
                                            }
                                            else {
                                                setValue(newValue);
                                                setHiddenButtons(false)
                                                console.log(selectedUsersforSkillEdit)
                                                for (let i = 0; i < selectedUsersforSkillEdit.length; i++) {
                                                    if (JSON.stringify(editedRatingUsers).includes(selectedUsersforSkillEdit[i])) {
                                                        editedRatingUsers.forEach(element => {
                                                            if (element.userId === selectedUsersforSkillEdit[i])
                                                                element.value = Number(newValue)
                                                        })
                                                    }
                                                    else
                                                        editedRatingUsers.push({ userId: selectedUsersforSkillEdit[i], value: Number(newValue) })

                                                }
                                                console.log(editedRatingUsers)
                                            }

                                        }} style={{ color: "#444a52" }}
                                    /><br />


                                </Fragment>
                            }
                        </div>

                        <div>
                            <Button disabled={!editedRatingUsers.length > 0} variant="contained" color='primary' style={{ textTransform: "none", margin: 5, backgroundColor: "rgb(35, 57, 93)", color: "white" }} onClick={() => { setMessage("Update ratings for <b>" + editedRatingUsers.length + "</b> member(s)?"); console.log("EditedRatingUsers: ", editedRatingUsers) }}> Assign</Button>
                            <Button variant="contained" style={{ textTransform: "none", margin: 5 }} onClick={() => { setNextButtonClicked(false); setEditedRatingUsers([]); setValue(0); setHiddenUpdateRating(true); setCheckBoxSet(false) }} > Cancel</Button>
                        </div>
                    </Fragment>
                }
            </Fragment>


        </Drawer>
    )
}

export default AddSkillsDrawer