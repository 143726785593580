import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from "@material-ui/core/LinearProgress";
import './ProgressBarModal.css'
import { BeatLoader } from 'react-spinners'

const ProgressBarModal = (props) => {
    const { isOpen, header, progress, min, max } = props

    const normalize = value => ((value - min) * 100) / (max - min);

    return (
        <Dialog fullWidth maxWidth={"xs"} className="progress-modal--centered animated fadeIn" open={isOpen}>
            <DialogTitle className="progress-modal--centered" style={{backgroundColor: "rgb(35, 57, 93)",color: "white"}}> {header} </DialogTitle>
            <DialogContent dividers className="progress-modal--centered">
                {progress < max ?
                    <LinearProgress variant="determinate" value={normalize(progress)} />
                    : <div className="waiting-modal__spinner">
                            <BeatLoader color="rgb(35, 57, 93)"/>
                      </div>
                }
            </DialogContent>
        </Dialog>
    )
}

export default ProgressBarModal;
