import React, { useState, useEffect, Fragment } from 'react';
import Rating from '@material-ui/lab/Rating';
import { makeStyles } from "@material-ui/styles";
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@material-ui/core';
import { capitalizeFirstLetter, sleep } from '../../services/utils';
import ConfirmationModal from '../modals/confirmationModal/ConfirmationModal';
import ProgressBarModal from '../modals/progressBarModal/ProgressBarModal';
import { bulkAssignOrRemove } from '../../services/backend';
import WaitingModal from '../modals/waitingModal/WaitingModal';
import AlertModal from '../modals/alertModal/AlertModal';
import './skillDetailsTable.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { setPullTaskStatus, setSaveBatchId } from '../../redux/orgData/orgDataSlice';

const SkillDetailsTable = (props) => {
  const dispatch = useDispatch();
  const token = sessionStorage.getItem('purecloud-csp-token')
  const env = sessionStorage.getItem('purecloud-csp-env')
  const { rowContent, hiddenColumns, styles, ratingSize, entityType, entityID, entityName, tableOnClick, resetFilterRatings, resetFilteredMembers, filterBy, filterData, isFilterEnabled, resetFilterEnabled } = props
  const defaultPageSize = 25;
  const [initialData, setInitialData] = useState([])
  const [dataSource, setDataSource] = useState([])
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const useStyles = makeStyles(styles)
  const classes = useStyles();
  const [hiddenButtons, setHiddenButtons] = useState(true)
  const [editedRatingUsers, setEditedRatingUsers] = useState([])
  const [message, setMessage] = useState()
  const [progressBarMax, setProgressBarMax] = useState(0)
  const [progressBarCurrent, setProgressBarCurrent] = useState(0)
  const [hiddenUpdateRating, setHiddenUpdateRating] = useState(true)
  const [value, setValue] = useState(0)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [progressBarHeaderText, setProgressBarHeaderText] = useState()
  const [workInProgress, setWorkInProgress] = useState(false)
  const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)
  const [waitingModalHeader, setWaitingModalHeader] = useState('')
  const [error, setError] = useState();
  const usersData = useSelector((state) => state.orgData.orgData.users);
  const listOfSaveBtachIds = useSelector((state) => state.orgData.latestSavedBatchId);
  const history = useHistory()

  useEffect(() => {
    console.log('SkillDetailsTable.useEffect.editedRatingUsers:', editedRatingUsers)
    const nEditedUsers = editedRatingUsers.length;

    if (nEditedUsers > 0) {
      if (isFilterEnabled) {
        setMessage(`${nEditedUsers} users ratings were modified, do you wish to save changes?`)
      } else {
        let editedUsers = {};

        for (let i = 0; i < editedRatingUsers.length; i++) {
          editedUsers[editedRatingUsers[i].userId] = editedRatingUsers[i];
        }

        const result = rowContent.map(element => {
          if (editedUsers.hasOwnProperty(element.id)) {
            element.proficiency = editedUsers[element.id].value;
          }
          return element;
        })

        console.log('SkillDetailsTable.useEffect.Setting refresh data source')
        setInitialData(result)
        setDataSource(result)
      }
    } else {
      console.log('SkillDetailsTable.useEffect.Setting data source')
      setInitialData([...rowContent])
      setDataSource(rowContent)
    }
    resetFilterEnabled();
  }, [props.rowContent])

  const RatingEditInputCell = (props) => {
    const { id, value, api, field, row } = props;
    const handleChange = (event) => {
      api.setEditCellValue(
        { id, field, value: Number(event.target.value) },
        event
      );
      if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
        api.commitCellChange({ id, field });
        api.setCellMode(id, field, "view");
      }
      setHiddenButtons(false);
      if (JSON.stringify(editedRatingUsers).includes(id)) {
        editedRatingUsers.forEach(element => {
          if (element.userId === id)
            element.value = Number(event.target.value)
        })
      }
      else
        setEditedRatingUsers([...editedRatingUsers, { userId: id, username: row.name, value: Number(event.target.value) }])
    };


    return (
      <div className={classes.root}>
        <Rating
          name="rating"
          precision={1}
          value={Number(value)}
          onChange={handleChange}
          size={ratingSize}
          style={{ color: "#444a52" }}
        />
      </div>
    );
  }

  const waitPopup = async (title) => {
    const wait = () => new Promise((resolve) => setTimeout(resolve, 2000));
    setIsWaitingModalOpen(true)
    setWaitingModalHeader(title)
    await wait();
    setIsWaitingModalOpen(false)
    setWaitingModalHeader("")
  }

  const editSkillRatings = async () => {
    console.log('SkillDetailsTable.editSkillRatings:', editedRatingUsers);
    setHiddenButtons(true)
    setMessage(undefined)
    setHiddenUpdateRating(true)
    setWorkInProgress(true)
    setProgressBarHeaderText('Submitting request to update skill rating on Genesys Cloud')

    let userListObject = {};
    if (usersData.length > 0) {
      usersData.forEach(element => {
        userListObject[element.id] = element;
      });
    }

    try {
      let bulkUpdate
      console.log('entityType:', entityType)
      switch (entityType.toLowerCase()) {
        case 'skill': bulkUpdate = editedRatingUsers.map(x => ({ userId: x.userId, proficiency: x.value, username: userListObject?.[x.userId]?.name, skillId: entityID }))
          break;
        case 'language': bulkUpdate = editedRatingUsers.map(x => ({ userId: x.userId, proficiency: x.value, username: userListObject?.[x.userId]?.name, languageId: entityID }))
          break;
        default:
          break;
      }

      if (bulkUpdate?.length > 0) {
        const batchId = await bulkAssignOrRemove(token, env, {
          entityName: entityName,
          entityId: entityID,
          bulk: bulkUpdate,
          operation: `Assign ${capitalizeFirstLetter(entityType)}`,
          type: entityType?.toLowerCase(),
          taskOwnerId: sessionStorage.getItem('userId'),
          taskOwnerName: sessionStorage.getItem('userName'),
          orgId: sessionStorage.getItem('orgId'),
        });

        await sleep(5000);
        await waitPopup("Request successfully submitted to Genesys Cloud");
        await waitPopup("Navigating to Task Tracker page to check status");
        setEditedRatingUsers([]);
        dispatch(setPullTaskStatus({ isPullTaskStatus: true }))
        dispatch(setSaveBatchId({ latestSavedBatchId: [...listOfSaveBtachIds, ...[{ entityName: entityName, batchId: batchId }]] }))
        history.push("/skills/skillResults?tabValue=status");
      }

    } catch (error) {
      setError(`An error occured while saving in skillDetails Table :${JSON.stringify(error.message)}`)
      console.log('error:', error)
    }
    finally {
      setIsWaitingModalOpen(false)
      setWorkInProgress(false)
    }
  }

  const cancelEditSkillRatings = () => {
    resetFilterRatings()
    console.log(filterBy, filterData)
    filterBy === 'all' ? (resetFilteredMembers('all', [])) : (resetFilteredMembers(filterBy, filterData))
    setEditedRatingUsers([]);
    setValue(0)
    setHiddenButtons(true);
    setMessage(undefined)
    setDataSource([...rowContent])
  }

  const tableRowSelected = (params) => {
    setSelectedUsers(params)
    if (params.length > 0)
      setHiddenUpdateRating(false)
    else {
      setHiddenUpdateRating(true)
      if (editedRatingUsers.length > 0)
        setHiddenButtons(false)
      else
        setHiddenButtons(true)
    }
    if (tableOnClick) tableOnClick(params)
  }

  const columnheaders = [
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      resizable: false,
      minWidth: 100,
      flex: 0.5,
      headerClassName: 'super-app-theme--header',
      hide: hiddenColumns && hiddenColumns.find(x => x === 'name') ? true : false
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      resizable: false,
      minWidth: 100,
      flex: 0.5,
      headerClassName: 'super-app-theme--header',
      hide: hiddenColumns && hiddenColumns.find(x => x === 'email') ? true : false
    },
    {
      field: "division",
      headerName: "Division",
      sortable: false,
      resizable: false,
      minWidth: 100,
      flex: 0.5,
      headerClassName: 'super-app-theme--header',
      hide: hiddenColumns && hiddenColumns.find(x => x === 'division') ? true : false
    },
    {
      field: "proficiency",
      headerName: "Rating",
      sortable: false,
      resizable: false,
      minWidth: 100,
      flex: 0.5,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => (
        <div className={classes.root}>
          <Rating readOnly value={params.value} size={ratingSize} style={{ color: "#444a52" }} />
        </div>),
      renderEditCell: RatingEditInputCell,
      editable: true,
      hide: hiddenColumns && hiddenColumns.find(x => x === 'proficiency') ? true : false
    },
  ];

  return (
    <Fragment>
      <WaitingModal isOpen={isWaitingModalOpen} header={waitingModalHeader} />
      <AlertModal
        isOpen={!!error}
        header="Error"
        toggle={() => {
          setError(undefined);
        }}
        body={error}
      />
      <ProgressBarModal
        isOpen={workInProgress}
        header={progressBarHeaderText}
        progress={progressBarCurrent}
        min={0}
        max={progressBarMax}
      />
      <ConfirmationModal
        isOpen={!!message}
        header="Ratings updates"
        body={message}
        button1={editSkillRatings}
        button2={cancelEditSkillRatings}
        button1Text="Yes"
        button2Text="No"
        redirectUrl="/skills/skillResults?tabValue="
      />
      <DataGrid
        rows={dataSource}
        columns={columnheaders}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[25, 50, 75, 100]}
        pagination
        checkboxSelection
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        onSelectionModelChange={tableRowSelected}
        showColumnRightBorder
      />
      <Paper elevation={3} style={{ marginTop: "5px", marginBottom: "5px" }}>
        {!hiddenUpdateRating && (
          <div className='d-flex justify-content-between align-items-center' style={{ padding: "10px" }}>
            <div>

              <label> Apply the same rating for the selected members</label>
              <br />
              <Rating
                name="simple-controlled"
                value={value}
                onChange={(event, newValue) => {
                  console.log("skillDetailsTable.newValue", newValue);
                  if (newValue == null) {
                    setValue(0);
                    setHiddenButtons(true);
                  } else {
                    setValue(newValue);
                    setHiddenButtons(false);
                    console.log(selectedUsers);
                    for (let i = 0; i < selectedUsers.length; i++) {
                      if (
                        JSON.stringify(editedRatingUsers).includes(
                          selectedUsers[i]
                        )
                      ) {
                        editedRatingUsers.forEach((element) => {
                          if (element.userId === selectedUsers[i])
                            element.value = Number(newValue);
                        });
                      } else
                        editedRatingUsers.push({
                          userId: selectedUsers[i],
                          value: Number(newValue),
                        });
                    }
                    console.log(editedRatingUsers);
                  }
                }}
                style={{ color: "#444a52" }}
              />
              <br />
            </div>
            <div className='d-flex'>
              {!hiddenButtons && (
                <Fragment>
                  <Button
                    variant="contained"
                    style={{
                      textTransform: "none",
                      marginLeft: 5,
                      backgroundColor: "rgb(35, 57, 93)",
                      color: "white",
                    }}
                    onClick={() => {
                      setMessage(
                        "Update ratings for <b>" +
                        editedRatingUsers.length +
                        "</b> member(s)?"
                      );
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    style={{ textTransform: "none", marginLeft: 5 }}
                    onClick={cancelEditSkillRatings}
                  >
                    Cancel
                  </Button>
                </Fragment>
              )}
            </div>
          </div>
        )}
      </Paper>
    </Fragment>
  );
}
export default SkillDetailsTable