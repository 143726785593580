import { createSlice } from "@reduxjs/toolkit";
import { getAllOrgDetails, getEntitiesAction } from "./orgDataActions";

const initialState = {
  orgData: {
    users: [],
    locations: [],
    groups: [],
    dynamicgroups: [],
    roles: [],
    divisions: [],
    entities: [],
    selectedEntity: {
      entityName: "",
      entityID: "",
      entityType: ""
    }
  },
  IsWaitingMdlOpen: false,
  dynamicgroupsEnabled: true,
  isAuthorized: false,
  isPullOrgDetailsTimer: 0,
  function: {},
  isPullTaskStatus: false,
  isOrgPullDataEnabled: false,
  latestSavedBatchId: [],
  listOfTaskJobs: []
};

const orgDataSlice = createSlice({
  name: "orgData",
  initialState,
  reducers: {
    setWaitingMdlOpen: (state, action) => {
      state.IsWaitingMdlOpen = action.payload.IsWaitingMdlOpen;
    },
    setAuthorization: (state, action) => {
      state.isAuthorized = action.payload.isAuthorized;
    },
    setOrgTimer: (state, action) => {
      state.isPullOrgDetailsTimer = action.payload.isPullOrgDetailsTimer;
    },
    addNewEntityToState: (state, action) => {
      let entities = state.orgData.entities;
      entities.push(action.payload);
      state.orgData.entities = (entities || []).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    },
    removeNewEntityFromState: (state, action) => {
      let entities = state.orgData.entities;
      const index = entities.findIndex(element => element.name == action.payload.name);
      entities.splice(index, 1);
      state.orgData.entities = entities;
    },
    setOrgDataFunction: (state, action) => {
      state.function.startOrgDataFunc = action.payload.startOrgDataFunc;
      state.function.stopOrgDataFunc = action.payload.stopOrgDataFunc;
      state.function.resetTimerOrgDataFunc = action.payload.resetTimerOrgDataFunc;
    },
    setSelectedEntity: (state, action) => {
      state.selectedEntity = action.payload
    },
    setPullTaskStatus: (state, action) => {
      state.isPullTaskStatus = action.payload.isPullTaskStatus
    },
    setTaskOperationFunction: (state, action) => {
      state.function.startTaskTimerFunc = action.payload.startTaskTimerFunc;
      state.function.stopTaskTimerFunc = action.payload.stopTaskTimerFunc;
      state.function.resetTaskTimerFunc = action.payload.resetTaskTimerFunc;
    },
    setSaveBatchId: (state, action) => {
      state.latestSavedBatchId = action.payload.latestSavedBatchId;
    },
    setListOfTaskJobs: (state, action) => {
      state.listOfTaskJobs = action.payload.listOfTaskJobs;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllOrgDetails.fulfilled, (state, action) => {
        state.orgData = action.payload.orgLatestData;
        state.latestSavedBatchId = action.payload.saveBatchIds;
        state.IsWaitingMdlOpen = false;
        state.dynamicgroupsEnabled = true;
      }).addCase(getAllOrgDetails.rejected, (state, action) => {
        state.isWaitingMdlOpen = false;
        state.dynamicgroupsEnabled = false;
      })
      .addCase(getEntitiesAction.pending, (state, action) => {
        state.isWaitingMdlOpen = true;
      })
      .addCase(getEntitiesAction.fulfilled, (state, action) => {
        state.orgData.entities = action.payload;
        state.isWaitingMdlOpen = false;
      })
      .addCase(getEntitiesAction.rejected, (state, action) => {
        state.isWaitingMdlOpen = false;
      })
  },
},
);

export const { setWaitingMdlOpen, setAuthorization, addNewEntityToState, removeNewEntityFromState,
  setOrgTimer, setOrgDataFunction, setSelectedEntity, setPullTaskStatus, setSaveBatchId, setListOfTaskJobs } = orgDataSlice.actions;

export default orgDataSlice.reducer;