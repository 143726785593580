import { useEffect, useRef, useState } from 'react'
import './Home.css'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllOrgDetails } from '../../redux/orgData/orgDataActions';
import { setWaitingMdlOpen } from '../../redux/orgData/orgDataSlice';
import backendAPI from "../../services/backend";

const Home = () => {
    const dispatch = useDispatch();
    const isAuthorized = useSelector((state) => state.orgData.isAuthorized);
    let isPullTaskStatus = useSelector((state) => state.orgData.isPullTaskStatus);
    let [orgTimerInterval, setOrgTimerInterval] = useState()
    let pullOrgDataTimer = useRef(0);
    let isRefreshNeededOrg = useRef(false);
    const token = sessionStorage.getItem('purecloud-csp-token');
    const env = sessionStorage.getItem('purecloud-csp-env');

    let latestFourTask = [];
    let latestTwentyTask = [];
    let pullOrgTimer = useRef(20);

    let [batchStatusInterval, setBatchStatusInterval] = useState()

    useEffect(() => {
        if (!isAuthorized) {
            return;
        }

        dispatch(setWaitingMdlOpen({ IsWaitingMdlOpen: true }));

        dispatch(getAllOrgDetails());
        setTimeout(() => {
            taskInterval();
        }, 30000);

        let activateTimer = document.getElementById("bulk-skill-page");
        activateTimer.addEventListener("mouseover", onStartGetAllOrgDataTimer);
    }, [isAuthorized]);

    // const onStartGetAllOrgDataTimer = () => {
    //     if (isRefreshNeededOrg.current) {
    //         pullOrgDataTimer.current = 0;`
    //         isRefreshNeededOrg.current = false;
    //         onRefresh();
    //         dispatch(getAllOrgDetails());
    //     }
    // }

    // useEffect(() => {
    //     isRefreshNeededOrg.current = true;
    //     setOrgTimerInterval(clearInterval(orgTimerInterval))
    // }, [isPullTaskStatus])

    // function onRefresh() {
    //     clearInterval(orgTimerInterval);
    //     orgTimerInterval = setInterval(async () => {
    //         pullOrgDataTimer.current++;
    //         console.log(`*** Pull OrgData Timer ${pullOrgDataTimer.current} ***`);
    //         if ((pullOrgDataTimer.current % (3 * 30)) === 0) {
    //             dispatch(getAllOrgDetails());
    //         }
    //         if (pullOrgDataTimer.current === (9 * 60)) {
    //             dispatch(getAllOrgDetails());
    //             isRefreshNeededOrg.current = true;
    //             setOrgTimerInterval(clearInterval(orgTimerInterval))
    //         }
    //     }, 1000);
    //     console.log("Org Timer Interval " + orgTimerInterval);
    // }

    function taskInterval() {
        let taskTimer = 0;
        setBatchStatusInterval(clearInterval(batchStatusInterval))
        batchStatusInterval = setInterval(async () => {
            taskTimer++;
            console.log(`*** Pull Task Status Timer ${taskTimer} ***`);
            if ((taskTimer % pullOrgTimer.current) === 0) {
                const response = await backendAPI.getTaskOperation(token, env);
                let filteredData = response?.data?.Items?.filter(item => new Date(item.startTime) >= new Date(Date.now() - 15 * 60 * 1000));
                filteredData = filteredData.sort((a, b) => a.startTime - b.startTime).splice(0, 20);

                if (latestFourTask.length > 0) {
                    let pullOrgData = false;
                    let successApiCount = 0;
                    let totalApiCount = 0;

                    for (let po = 0; po < latestFourTask.length; po++) {
                        const result = filteredData?.find(x => x.batchId === latestFourTask[po]?.batchId);
                        if (result && result.status === "Completed" || result.status === "Failed") {
                            pullOrgData = true;
                            latestFourTask.splice(po, 1);

                            let totalInprogress = 0;
                            filteredData?.forEach(ele => {
                                if (ele?.status !== "Completed" && totalInprogress <= 4) {
                                    latestFourTask.push({ "batchId": ele.batchId, "entityName": ele.entityName });
                                    totalApiCount += ele?.totalCount;
                                    successApiCount += ele?.successCount;
                                    totalInprogress++;
                                }
                            });
                        }
                    }
                    if (pullOrgData) {
                        pullOrganizationData(5000);
                        pullOrgData = false;
                        const pendingApiCount = totalApiCount - successApiCount;
                        pullOrgTimer.current = pendingApiCount <= 1000 ? 60 : pendingApiCount <= 1500 ? 80 : pendingApiCount > 2500 ? 90 : 30;
                    }
                } else {
                    if (filteredData.length > 0) {
                        if (latestFourTask.length <= 0) {
                            let tenApiJobsList = filteredData?.filter(item => new Date(item.startTime) >= new Date(Date.now() - 1 * 60 * 1000));
                            if (tenApiJobsList.length > 0) {
                                latestFourTask = tenApiJobsList;
                            } else {
                                pullOrgTimer.current = 20;
                            }
                        }
                    }
                }
            }

            if (taskTimer === (5 * 60)) {
                setBatchStatusInterval(clearInterval(batchStatusInterval))
                isRefreshNeededOrg.current = true;
            }
        }, 1000);
    }

    const pullOrganizationData = (timer) => {
        setTimeout(() => {
            dispatch(getAllOrgDetails());
        }, timer);
    }

    const onStartGetAllOrgDataTimer = () => {
        if (isRefreshNeededOrg.current) {
            isRefreshNeededOrg.current = false;
            taskInterval();
        }
    }

    return (
        <></>
    )
}

export default Home