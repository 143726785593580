import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import WarningIcon from '@material-ui/icons/Warning';
import Close from '@material-ui/icons/Close';
import ProgressBarModal from '../../modals/progressBarModal/ProgressBarModal'
import { bulkAssignOrRemove } from '../../../services/backend';
import WaitingModal from '../../modals/waitingModal/WaitingModal'
import AlertModal from '../../modals/alertModal/AlertModal'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DialogContentText from '@material-ui/core/DialogContentText';
import { setPullTaskStatus, setSaveBatchId } from '../../../redux/orgData/orgDataSlice';


const SkillDetailModal = (props) => {
    const dispatch = useDispatch();
    const token = sessionStorage.getItem('purecloud-csp-token');
    const env = sessionStorage.getItem('purecloud-csp-env');
    const { open, handleClose, modalTitle, modalContent, button1, button2, entityType, entityName, entityID, selectedMembers, redirectUrl } = props;
    const [membersToDelete, setMembersToDelete] = useState([]);
    const [progressBarMax, setProgressBarMax] = useState(0);
    const [progressBarCurrent, setProgressBarCurrent] = useState(0);
    const [progressBarHeaderText, setProgressBarHeaderText] = useState();
    const [workInProgress, setWorkInProgress] = useState(false);
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false);
    const [waitingModalHeader, setWaitingModalHeader] = useState('');
    const [error, setError] = useState();
    const usersData = useSelector((state) => state.orgData.orgData.users);
    const history = useHistory()
    const listOfSaveBtachIds = useSelector((state) => state.orgData.latestSavedBatchId);

    const waitPopup = async (title) => {
        const wait = () => new Promise((resolve) => setTimeout(resolve, 2000));
        setIsWaitingModalOpen(true)
        setWaitingModalHeader(title)
        await wait();
        setIsWaitingModalOpen(false)
        setWaitingModalHeader("")
    }

    const deleteSkillsFromUser = async () => {
        //TO remove selected users from skills
        console.log("Members to be removed: " + selectedMembers)
        for (let i = 0; i < selectedMembers.length; i++) {
            membersToDelete.push({ userId: selectedMembers[i] })
        }
        handleClose()
        setWorkInProgress(true)
        setProgressBarHeaderText('Removing selected users')

        let userListObject = {};
        if (usersData.length > 0) {
            usersData.forEach(element => {
                userListObject[element.id] = element;
            });
        }

        // format GC api call
        let bulkUpdate
        console.log('entityType:', entityType)
        switch (entityType.toLowerCase()) {
            case 'skill': bulkUpdate = membersToDelete.map(x => ({ userId: x.userId, skillId: entityID, username: userListObject[x.userId]?.name }))
                break;
            case 'language': bulkUpdate = membersToDelete.map(x => ({ userId: x.userId, languageId: entityID, username: userListObject[x.userId]?.name }))
                break;
            default:
                break;
        }

        // delete skill from users + retries
        if (bulkUpdate) {
            let nTotalUpdates = bulkUpdate.length
            setProgressBarMax(nTotalUpdates)
            if (entityType) {
                const batchId = await bulkAssignOrRemove(token, env, {
                    entityName: entityName,
                    entityId: entityID,
                    bulk: bulkUpdate,
                    operation: 'Remove Users',
                    type: entityType?.toLowerCase(),
                    taskOwnerId: sessionStorage.getItem('userId'),
                    taskOwnerName: sessionStorage.getItem('userName'),
                    orgId: sessionStorage.getItem('orgId'),
                })
                setProgressBarHeaderText('Complete!')
                await waitPopup("Request successfully submitted to Genesys Cloud");
                await waitPopup("Navigating to Task Tracker page to check status");
                setProgressBarCurrent(0)
                setProgressBarMax(0)
                setMembersToDelete([])
                setWorkInProgress(false)
                dispatch(setSaveBatchId({ latestSavedBatchId: [...listOfSaveBtachIds, ...[{ entityName: entityName, batchId: batchId }]] }))
                dispatch(setPullTaskStatus({ isPullTaskStatus: true }))
                history.push("/skills/skillResults?tabValue=status");
            }
        }
        setIsWaitingModalOpen(false);
        history.push(redirectUrl);
    }

    return (
        <div id="modal-container">
            <WaitingModal
                isOpen={isWaitingModalOpen}
                header={waitingModalHeader}
            />
            <ProgressBarModal
                isOpen={workInProgress}
                header={progressBarHeaderText}
                progress={progressBarCurrent}
                min={0}
                max={progressBarMax}
            />
            <AlertModal
                isOpen={!!error}
                header='Error'
                toggle={() => { setError(undefined) }}
                body={error}
            />
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle className="confirmation-modal-header" style={{ backgroundColor: "rgb(35, 57, 93)", color: "white", textAlign: "center" }}>
                    <div className="d-flex justify-content-between align-items-center"> <span className="d-flex align-items-center"><WarningIcon style={{ marginRight: "5px" }} />{modalTitle}</span>
                        <Button style={{ float: 'right', textTransform: 'none', backgroundColor: "rgb(35, 57, 93)", color: "white" }} onClick={handleClose}> <Close /> </Button></div>
                </DialogTitle >
                <DialogContent dividers>
                    <DialogContentText style={{ fontSize: 20 }}>  <div dangerouslySetInnerHTML={{ __html: modalContent }} /></DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color='primary' style={{ textTransform: 'none', backgroundColor: "rgb(35, 57, 93)", color: "white" }} onClick={deleteSkillsFromUser}>{button1}</Button>
                    <Button variant="contained" color="white" style={{ textTransform: 'none' }} onClick={handleClose}>{button2}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )

}
export default SkillDetailModal;