import React, { useEffect, useState } from 'react';
import { Button, Drawer } from '@material-ui/core';
import GroupsSelector from '../groupsSelector/GroupsSelector';
import SelectedGroupMembersTable from '../table/selectedGroupMembersTable';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import utils from '../../services/utils';
import './AddGroupsDrawer.css';
import { useSelector } from 'react-redux';

const AddGroupsDrawer = (props) => {
    const { open, toggle, anchor, selectedAgents, toggleCloseDrawer, dynamicgroupsEnabled } = props
    const [data, setData] = useState({ undefined })
    const [loading, setLoading] = useState(false)
    const token = sessionStorage.getItem('purecloud-csp-token')
    const env = sessionStorage.getItem('purecloud-csp-env')
    const [selectedUsers, setSelectedUsers] = useState([])
    const [numberOfSelectedUsers, setNumberOfSelectedUsers] = useState([])
    const [selectedGroups, setSelectedGroups] = useState([])
    const [disableAdd, setDisableAdd] = useState(true);
    const [filterBy, setFilterBy] = useState('allgroups')
    const [filterData, setFilterData] = useState('allgroups')
    const usersData = useSelector((state) => state.orgData.orgData.users);
    const groupsData = useSelector((state) => state.orgData.orgData.groups);
    const dynamicGroups = useSelector((state) => state.orgData.orgData.dynamicGroups);
    let selectedMembers = []
    const [height, setHeight] = useState("100vh");

    useEffect(() => {
        const handleResize = () => {
            const newHeight = document.documentElement.clientHeight;
            setHeight(`${newHeight - 0}px`);
        };

        window.addEventListener("resize", handleResize);
        handleResize();
        setData([])
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const removeDuplicates = (array) => {
        let jsonObject = array.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        return (uniqueArray);
    }

    useEffect(() => {
        (async () => {
            console.log('filter happened')
            try {
                console.info('AddGroupsDrawer.filtersChanged.filterBy', filterBy)
                console.info('AddGroupsDrawer.filtersChanged.filterData', filterData)
                setSelectedGroups(filterData)
                let pendingUsers = [], users, groups, allGroups, selectedGroups = []
                let groupUsers = []
                let currentGroupUsers = []
                switch (filterBy) {
                    case 'allgroups':
                        users = JSON.parse(JSON.stringify(usersData))
                        allGroups = JSON.parse(JSON.stringify([...groupsData, ...dynamicGroups]))

                        for (let i = 0; i < filterData.length; i++) {
                            if (filterData[i].type === "dynamic") {
                                setLoading(true)
                                //fetch dynamic group members
                                let allMembersRetrieved = false;
                                let sgMembersListAPIURL = `/api/v2/routing/skillgroups/${filterData[i].id}/members?pageSize=100`;
                                while (!allMembersRetrieved) {
                                    try {
                                        const getMembersResponse = await utils.fetchWrapper(`https://api.${env}${sgMembersListAPIURL}`,
                                            {
                                                method: "GET",
                                                headers: {
                                                    "Content-Type": "application/json",
                                                    Authorization: `bearer ${token}`,
                                                },
                                            })
                                        if (getMembersResponse && getMembersResponse.entities && getMembersResponse.entities.length > 0) {
                                            currentGroupUsers = currentGroupUsers.concat(getMembersResponse.entities);
                                            if (getMembersResponse.nextUri) {
                                                sgMembersListAPIURL = getMembersResponse.nextUri;
                                            } else {
                                                allMembersRetrieved = true;
                                            }
                                        } else allMembersRetrieved = true;
                                    } catch (error) {
                                        console.error(error)
                                    }
                                }
                                console.log("AddGHroupsDrawer.COunt", currentGroupUsers)
                                for (let k = 0; k < currentGroupUsers.length; k++) {
                                    if (!groupUsers.find(x => x.id === currentGroupUsers[k].id)) {
                                        groupUsers.push(currentGroupUsers[k])
                                    }
                                }
                                for (let j = 0; j < groupUsers.length; j++) {
                                    if (groupUsers[j].selectedGroups)
                                        groupUsers[j].selectedGroups = [...groupUsers[j].selectedGroups, filterData[i].name]
                                    else
                                        groupUsers[j].selectedGroups = [filterData[i].name]
                                }
                                setLoading(false)
                            }
                            else
                                pendingUsers = [...pendingUsers, ...users.filter(user => user.groups.find(group => group.id === filterData[i].id))]
                        }

                        groupUsers = removeDuplicates(groupUsers)
                        for (let i = 0; i < groupUsers.length; i++)
                            pendingUsers = [...pendingUsers, ...users.filter(x => x.id === groupUsers[i].id)]


                        for (let i = 0; i < pendingUsers.length; i++) {
                            for (let j = 0; j < groupUsers.length; j++) {
                                if (groupUsers[j].id === pendingUsers[i].id)
                                    pendingUsers[i].selectedGroups = groupUsers[j].selectedGroups.toString()
                            }
                            for (let j = 0; j < filterData.length; j++) {
                                if (pendingUsers[i].groups.find(x => x.id === filterData[j].id)) {
                                    if (!pendingUsers[i]?.selectedGroups) {
                                        pendingUsers[i].selectedGroups = allGroups.find(x => x.id === filterData[j].id).name
                                    } else {
                                        pendingUsers[i].selectedGroups = pendingUsers[i].selectedGroups + ',' + allGroups.find(x => x.id === filterData[j].id).name
                                    }

                                }
                            }
                            pendingUsers[i].selectedGroups = removeDuplicates(pendingUsers[i].selectedGroups.split(','))
                        }
                        pendingUsers = removeDuplicates(pendingUsers)

                        window.bsa_selectedGroupMembers = JSON.stringify(pendingUsers);
                        console.log('AddGroupsDrawer.Allgroups.pendingUser:', pendingUsers)
                        setData([...pendingUsers])
                        break;
                    case 'staticgroups':
                        users = JSON.parse(JSON.stringify(usersData))
                        allGroups = JSON.parse(JSON.stringify(groupsData))
                        pendingUsers = users
                            .filter(user => user.groups.find(group => filterData.find(x => x.id === group.id)))
                        for (let i = 0; i < pendingUsers.length; i++) {
                            for (let j = 0; j < filterData.length; j++) {
                                if (pendingUsers[i].groups.find(x => x.id === filterData[j].id)) {
                                    if (pendingUsers[i].selectedGroups === undefined)
                                        pendingUsers[i].selectedGroups = allGroups.find(x => x.id === filterData[j].id).name
                                    else
                                        pendingUsers[i].selectedGroups = pendingUsers[i].selectedGroups + ',' + allGroups.find(x => x.id === filterData[j].id).name

                                }
                            }
                            pendingUsers[i].selectedGroups = removeDuplicates(pendingUsers[i].selectedGroups.split(','))
                        }
                        pendingUsers = removeDuplicates(pendingUsers)
                        window.bsa_selectedGroupMembers = JSON.stringify(pendingUsers)
                        console.log('AddGroupsDrawer.Allgroups.pendingUser:', pendingUsers)
                        setData([...pendingUsers])
                        break;
                    case 'dynamicgroups':
                        users = JSON.parse(JSON.stringify(usersData))
                        allGroups = JSON.parse(JSON.stringify(dynamicGroups))
                        console.log("AddGroupsDrawer.FiltersChanged", allGroups)

                        for (let i = 0; i < filterData.length; i++) {
                            console.log(filterData[i])
                            setLoading(true)
                            let allMembersRetrieved = false;
                            let sgMembersListAPIURL = `/api/v2/routing/skillgroups/${filterData[i].id}/members?pageSize=100`;
                            while (!allMembersRetrieved) {
                                try {
                                    const getMembersResponse = await utils.fetchWrapper(`https://api.${env}${sgMembersListAPIURL}`,
                                        {
                                            method: "GET",
                                            headers: {
                                                "Content-Type": "application/json",
                                                Authorization: `bearer ${token}`,
                                            },
                                        })
                                    if (getMembersResponse && getMembersResponse.entities && getMembersResponse.entities.length > 0) {
                                        currentGroupUsers = currentGroupUsers.concat(getMembersResponse.entities);
                                        if (getMembersResponse.nextUri) {
                                            sgMembersListAPIURL = getMembersResponse.nextUri;
                                        } else {
                                            allMembersRetrieved = true;
                                        }
                                    } else allMembersRetrieved = true;
                                } catch (error) {
                                    console.error(error)
                                }
                            }
                            console.log("AddGroupsDrawer.FiltersChanged", currentGroupUsers)
                            for (let k = 0; k < currentGroupUsers.length; k++) {
                                if (!groupUsers.find(x => x.id === currentGroupUsers[k].id)) {
                                    groupUsers.push(currentGroupUsers[k])
                                }
                            }
                            for (let j = 0; j < groupUsers.length; j++) {
                                // debugger
                                if (groupUsers[j].selectedGroups)
                                    groupUsers[j].selectedGroups = [...groupUsers[j].selectedGroups, filterData[i].name]
                                else
                                    groupUsers[j].selectedGroups = [filterData[i].name]
                            }
                            setLoading(false)
                        }

                        groupUsers = removeDuplicates(groupUsers)

                        for (let i = 0; i < groupUsers.length; i++)
                            pendingUsers = [...pendingUsers, ...users.filter(x => x.id === groupUsers[i].id)]

                        for (let i = 0; i < pendingUsers.length; i++) {
                            pendingUsers[i].selectedGroups = groupUsers.filter(x => x.id === pendingUsers[i].id).selectedGroups
                        }
                        pendingUsers = removeDuplicates(pendingUsers)
                        window.bsa_selectedGroupMembers = JSON.stringify(pendingUsers)
                        console.log('AddGroupsDrawer.Allgroups.pendingUser:', pendingUsers)
                        setData([...pendingUsers])
                        break;
                    default:
                        break;
                }
            } catch (err) {
                console.error(err);
            }
        })();
    }, [filterBy, filterData, usersData, groupsData, dynamicGroups])

    const filtersChanged = async (filterBy, filterData) => {
        setFilterBy(filterBy);
        setFilterData(filterData);
    }

    const handleCloseDrawer = () => {
        setData([])
        toggleCloseDrawer(false)
    }
    const handleAddMembers = () => {
        let users = JSON.parse(window.bsa_selectedGroupMembers);
        for (let i = 0; i < selectedUsers.length; i++) {
            selectedMembers = [...selectedMembers, ...users.filter(user => user.id === selectedUsers[i])]
        }
        console.log('AddGroupsDrawer.selectedMembers: ', selectedMembers)
        selectedAgents(selectedMembers, selectedGroups)
        setData([])
        toggleCloseDrawer(false)
    }

    const currentlySelected = (params) => {
        setSelectedUsers(params)
        setNumberOfSelectedUsers(params.length)
        console.log('AddGroupsDrawer.CurrentlySelected: ', params)
        if (params.length > 0)
            setDisableAdd(false)
        else
            setDisableAdd(true)
    }

    return (
        <Drawer open={open} anchor={anchor} onClose={toggle(false)}>
            <div hidden={!loading} style={{ position: "fixed", top: "50%", left: "84%" }}>
                <CircularProgress /><br />
            </div>
            <div style={{ margin: "10px", marginLeft: 5, marginBottom: 10 }} className='d-flex justify-content-between align-items-center'>
                <h5 style={{ fontWeight: "700" }}>Add Groups</h5>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDrawer}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <GroupsSelector
                allgroups={JSON.parse(JSON.stringify([...groupsData, ...dynamicGroups]))}//change after backend integration
                staticgroups={JSON.parse(JSON.stringify(groupsData))}//change after backend integration
                dynamicgroups={JSON.parse(JSON.stringify(dynamicGroups))}//change after backend integration
                selectedFiltersChanged={filtersChanged}
                styles={{
                    root: {
                        marginLeft: 5,
                        marginRight: 5,
                        marginBottom: 2
                    }
                }}
                dynamicgroupsEnabled={dynamicgroupsEnabled}
            />
            <div style={{ margin: "5px", height: height }}>
                <SelectedGroupMembersTable
                    rowContent={data}
                    hiddenColumns={['groups', 'division']}
                    tableOnClick={currentlySelected}
                    selectedGroups={selectedGroups}
                />
            </div>
            <div>
                <Button variant="contained" disabled={disableAdd} color='primary' style={{ textTransform: 'none', float: 'left', margin: 5, backgroundColor: "rgb(35, 57, 93)", color: "white" }} onClick={handleAddMembers}>Add</Button>
                <Button style={{ textTransform: 'none', float: 'left', margin: 5 }} color='primary' onClick={handleCloseDrawer}>Cancel</Button>
            </div>
        </Drawer>
    )
}

export default AddGroupsDrawer