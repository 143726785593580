import { DataGrid } from "@mui/x-data-grid";
import SearchIcon from "@material-ui/icons/Search";
import DownloadIcon from "@mui/icons-material/Download";
import { TextField, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CachedIcon from "@mui/icons-material/Cached";
import { useEffect, useState, useRef } from "react";
import backendAPI from "../../../../services/backend";
import WaitingModal from "../../../modals/waitingModal/WaitingModal";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {
    '& .MuiDataGrid-cell': {
      borderRight: '1px solid #e7e7e7',
    },
  },
});

const TaskStatus = (props) => {
  const classes = useStyles();
  const listOfSaveBtachIds = useSelector((state) => state.orgData.latestSavedBatchId);
  const token = sessionStorage.getItem("purecloud-csp-token");
  const env = sessionStorage.getItem("purecloud-csp-env");
  const [pageSize, setPageSize] = useState(25);
  const [getSearchText, setSearchText] = useState("");
  const [getFilteredTaskOperation, setFilteredTaskOperation] = useState([]);
  const [getAllTaskOperation, setAllTaskOperation] = useState([]);
  const [getWaitingModal, setWaitingModal] = useState({
    open: false,
    header: "",
  });
  const [isRefreshDisabled, setRefreshDisabled] = useState(false);
  const [height, setHeight] = useState("100vh");
  const latestSavedBatchId = useSelector((state) => state.orgData.latestSavedBatchId);
  let [taskTabTimerInterval, setTaskTabTimerInterval] = useState();
  let taskTimer = useRef(0);;

  const columns = [
    {
      field: "entityName",
      headerName: "Skills",
      sortable: false,
      resizable: false,
      minWidth: 100,
      flex: 0.40,
      type: "string",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "operation",
      headerName: "Operation Type",
      sortable: false,
      resizable: false,
      minWidth: 100,
      flex: 0.35,
      type: "string",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "taskOwnerName",
      headerName: "Task Owner",
      sortable: false,
      resizable: false,
      minWidth: 100,
      flex: 0.35,
      type: "string",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      resizable: false,
      minWidth: 100,
      renderCell: (params) => <TextWithIconDownload {...params} />,
      flex: 0.55,
      type: "string",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "startTime",
      headerName: "Submission Date",
      sortable: false,
      resizable: false,
      minWidth: 100,
      flex: 0.35,
      type: "string",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.row.startTime) {
          const utcDate = new Date(params.row.startTime);
          const localDateString = new Date(utcDate).toLocaleString();
          return <span>{localDateString}</span>;
        }
        return "";
      },
    }
  ];

  useEffect(() => {
    (async () => {
      try {
        setWaitingModal({
          open: true,
          header: "Loading Task Tracker",
        });
        await getTaskOperations();
        setWaitingModal({
          open: false,
          header: "",
        });

        // const handleResize = () => {
        //   const newHeight = document.documentElement.clientHeight;
        //   setHeight(`${newHeight - 210}px`);
        // };

        // window.addEventListener("resize", handleResize);
        // handleResize();

        // return () => {
        //   window.removeEventListener("resize", handleResize);
        // };
      } catch (err) {
        console.log("Error init: ", err);
      }
    })();
    onRefresh()
    return () => {
      clearInterval(taskTabTimerInterval);
    }
  }, []);

  function onRefresh() {
    clearInterval(taskTabTimerInterval);
    taskTimer.current = 0;
    taskTabTimerInterval = setInterval(async () => {
      taskTimer.current++;
      console.log(`*** Task Tab  ${taskTimer.current} ***`);
      if ((taskTimer.current % (1 * 15)) === 0) {
        taskTimer.current = 0;
        await getTaskOperations();
      }
    }, 1000);
  }

  const TextWithIconDownload = (params) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{params.formattedValue}</span>
        {params.row.isFailed && <DownloadIcon style={{ marginLeft: '20px', cursor: 'pointer', color: '#767879' }} onClick={handleDownloadFile(params.row)} />}
      </div>
    );
  };

  const getTaskOperations = async () => {
    try {
      const response = await backendAPI.getTaskOperation(token, env);
      console.log(response);
      setAllTaskOperation(response?.data?.Items || []);
    } catch (err) {
      console.log("Error getTaskOperations :", err);
      setAllTaskOperation([]);
      setSearchText("");
      setFilteredTaskOperation([]);
      setWaitingModal({
        open: false,
        header: "",
      });
    }
  };

  const handleDownloadFile = (row) => async () => {
    try {
      const response = await backendAPI.getFailedBatchDetail(token, env, {
        batchId: row.batchId,
      });

      const data = response?.data?.userDetailList || [];
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([wbout], { type: "application/octet-stream" });

      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = `${row.entityName} - ${row.batchId}.xlsx`;
      link.click();

      URL.revokeObjectURL(url);
    } catch (err) {
      console.log("Error getFailedBatchDetail :", err);
    }
  };

  const handleTextSearchOperation = (event) => {
    const value = (event.target.value || "").toLowerCase();
    setSearchText(value);
  };

  useEffect(() => {
    const filteredData = getAllTaskOperation.filter((item) => {
      for (let key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(getSearchText)
        ) {

          return true;
        }
      }
      return false;
    }).map(element => {
      let messageStatus = '';
      let result = {};
      result = { ...element }
      if (result.status === 'Completed') {
        for (let i = 0; i < listOfSaveBtachIds.length; i++) {
          const isBatchIdPresent = listOfSaveBtachIds.find(x => x.batchId === result.batchId);
          if (isBatchIdPresent) {
            if (result?.status === 'Completed') {
              result.status = "Syncing Data";
            }
          }
        }
      } else if (result.status === 'Queued for Execution') {
        result.status = 'Queued for Execution';
      } else {
        messageStatus = getStatusMessage(messageStatus, result);
        result.status = messageStatus;
      }
      return result;
    });
    const sortedData = filteredData.sort((a, b) => new Date(b.startTime) - new Date(a.startTime));
    setFilteredTaskOperation(sortedData);
  }, [getSearchText, getAllTaskOperation]);

  const start = () => {
    (async () => {
      try {
        setWaitingModal({
          open: true,
          header: "Loading Task Tracker",
        });
        await getTaskOperations();
        setWaitingModal({
          open: false,
          header: "",
        });
      } catch (err) {
        console.log("Error init: ", err);
      }
    })();
    setRefreshDisabled(true);
    setTimeout(() => {
      setRefreshDisabled(false);
    }, 2000)
  };

  useEffect(() => {
    (async () => {
      await getTaskOperations();
    })
  }, [latestSavedBatchId])

  const getStatusMessage = (messageStatus, element) => {
    if (element?.status !== 'Completed') {
      messageStatus = ` ${element?.successCount} Users Out of ${element?.totalCount}`;
      if (element?.operation == 'Assign Skill' || element?.operation == 'Assign Language') {
        messageStatus += ' Assigned';
      } else {
        messageStatus += ' Remove Users';
      }

      if (element?.status == 'Failed') {
        messageStatus += ' (Failed)',
          element.isFailed = true;
      } else {
        element.isFailed = false;
      }
    }
    return messageStatus;
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <h5 style={{ fontWeight: '700' }}>Task Operation</h5>
        <div style={{ width: "30%" }} className="d-flex justify-content-between">
          <TextField
            style={{ width: "70%" }}
            InputProps={{
              endAdornment: <SearchIcon style={{ color: "#e0e0e0" }} />,
            }}
            label="Search"
            defaultValue=""
            variant="outlined"
            size="small"
            value={getSearchText}
            onChange={handleTextSearchOperation}
          />
          <Button
            variant="contained"
            style={{
              marginLeft: "5%",
              backgroundColor: "rgb(35, 57, 93)",
              color: "white",
            }}
            onClick={start}
            disabled={isRefreshDisabled}
          >
            <CachedIcon />
            &nbsp;Refresh
          </Button>
        </div>
      </div>
      <WaitingModal
        isOpen={getWaitingModal.open}
        header={getWaitingModal.header}
      />
      <div style={{ width: "100%", height: height, margin: "5px 0px" }}>
        <DataGrid
          rows={getFilteredTaskOperation}
          columns={columns}
          pageSize={pageSize}
          getRowId={(row) => row.batchId}
          rowsPerPageOptions={[25, 50, 75, 100]}
          pagination
          disableColumnFilter
          className={classes.root}
          disableColumnMenu
          hideFooterSelectedRowCount
          showColumnRightBorder
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        />
      </div>
    </>
  );
};

export default TaskStatus;
